import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect, useEffect } from "react";
import ReactDataTable from "../../../components/table/ReactDataTable";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Row,
  Col,
  ModalComponent,
  TextInput,
} from "../../../components/Components";
import {
  onChangePageState,
  onFormCancel,
  toggleModal,
} from "../../../redux/features/organisationSlice";
import { useEditOrganisationMutation, useGetOrganisationsQuery } from "../../../redux/api/organisations/organisationApi";
import { organisationColumns } from "./DataColumns";
import {
  Breadcrumb,
  BreadcrumbItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Organisations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.organisations);
  const { data, isFetching } = useGetOrganisationsQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {},
  });
  const [
    editOrganisation,
    {
      isLoading: isEditOrganisationLoading,
      isSuccess: isEditOrganisationSuccess,
      isError: isEditOrganisationError,
      error: editOrganisationError,
    },
  ] = useEditOrganisationMutation();
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.organisations);
      setTotalItems(data.total);
    }
  }, [data]);

  useEffect(() => {
    if (isEditOrganisationSuccess) {
      dispatch(toggleModal({ type: "edit" }));
      reset();
    }
    if (isEditOrganisationError && editOrganisationError) {
      toast.error(editOrganisationError?.data?.message);
    }
  }, [isEditOrganisationSuccess, isEditOrganisationError]);

  const onAddBtnClicked = () => {
  };

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "details":
        navigate(`../${row.id}`);
        break;
        case "edit":
          dispatch(toggleModal({ type: "edit", item: row }));
          reset({
            ...row,
          });
          break;
      default:
    }
  };

  const onFormSubmit = (data) => {
    editOrganisation({
      id: state.modal.item.id,
      ...data,
    });
  };

  return (
    <>
      <Head title="Organisations"></Head>
      <Content>
        <Block>
          <BlockHead>
            <Breadcrumb
              tag="nav"
              listTag="div"
              className="breadcrumb-arrow fs-18px"
            >
              <BreadcrumbItem tag="a" href="../">
                Home
              </BreadcrumbItem>
              <BreadcrumbItem active>Organisations</BreadcrumbItem>
            </Breadcrumb>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Organisations</BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <div className="table-wrapper is-scrollable">
              <ReactDataTable
                data={tableData}
                columns={organisationColumns}
                offset={state.offset}
                itemsPerPage={state.itemsPerPage}
                pagination
                totalItems={totalItems}
                progressPending={isFetching}
                className="nk-tb-list"
                selectableRows
                changePageState={changePageState}
                onRowClicked={onRowClicked}
              />
            </div>
          </PreviewCard>
        </Block>
      </Content>

      <ModalComponent
        isOpen={state.modal.edit}
        title={`Edit`}
        onModalCancel={() => {
          dispatch(onFormCancel());
          reset();
        }}
        toggle={() => {
          dispatch(onFormCancel());
          reset();
        }}
        backdrop={"static"}
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row className="g-3">
             <TextInput
                name={"name"}
                label={"Name"}
                required
                register={{
                  ...register("name", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
                md="12"
              />
               <TextInput
                name={"website"}
                label={"Website"}
                required
                register={{
                  ...register("website", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
                md="12"
              />

            <Col size="12">
              <Button
                color="primary"
                type="submit"
                disabled={isEditOrganisationLoading}
              >
                {(isEditOrganisationLoading) && (
                  <Spinner type="grow" size="sm" />
                )}
                <span>{"Edit"}</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>
    </>
  );
};


export default Organisations;
