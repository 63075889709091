import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect, useEffect } from "react";
import ReactDataTable from "../../../components/table/ReactDataTable";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Col,
  TextareaInput,
  SelectField,
  ModalComponent,
  Row,
  DatePickerInput,
  TimePickerInput,
  TextInput,
} from "../../../components/Components";
import { goalTrackingColumns } from "./DataColumns";
import {
  onChangePageState,
  toggleModal,
  onFormCancel,
} from "../../../redux/features/app-portal/logs/goalTrackingSlice";
import {
  useAssignGoalForUserMutation,
  useUnAssignGoalForUserMutation,
  useGetGoalsQuery,
  useLazyGetGoalsQuery,
  useLazyGetUserGoalsProgressQuery,
  useUpdateUserGoalsProgressMutation,
} from "../../../redux/api/goal-management/goalApi";
import { Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { AsyncSelectField } from "../../../components/form/SelectField";
import { useLazyGetAppUsersQuery } from "../../../redux/api/users/usersApi";
import { toast } from "react-toastify";
import moment from "moment";
import { getFirstDayOfWeek, getLastDayOfWeek } from "../../../utils/utils";

const AppGoalTracking = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.goalTracking);
  const [
    getUserGoalsProgress,
    { data, isFetching, isSuccess, isError, error },
  ] = useLazyGetUserGoalsProgressQuery();
  const [getAppUsers, { data: appUsers, isFetching: isGetAppUsersFetching }] =
    useLazyGetAppUsersQuery();
  const [
    assignGoal,
    {
      isLoading: isAssignLoading,
      isSuccess: isAssignSuccess,
      isError: isAssignError,
      error: assignError,
    },
  ] = useAssignGoalForUserMutation();
  const [
    updateUserGoalProgress,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateUserGoalsProgressMutation();
  const [
    unAssignGoal,
    {
      isLoading: isUnAssignLoading,
      isSuccess: isUnAssignSuccess,
      isError: isUnAssignError,
      error: unAssignError,
    },
  ] = useUnAssignGoalForUserMutation();
  const defaultValues = {
    goal: null,
    target: null,
    start_date: null,
    end_date: null,
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm(defaultValues);
  const [
    getGoalOptions,
    {
      data: goalData,
      isFetching: isGetGoalFetching,
      isSuccess: isGetGoalSuccess,
      isError: isGetGoalError,
      error: getGoalError,
    },
  ] = useLazyGetGoalsQuery({
    limit: 1000,
  });
  const [tableData, setTableData] = useState(data?.goals ?? []);
  const [totalItems, setTotalItems] = useState(data?.total ?? 0);
  const [currentUser, setCurrentUser] = useState(null);
  const [goalOptions, setGoalOptions] = useState([]);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useEffect(() => {
    if (currentUser) {
      var now = new Date();
      getUserGoalsProgress({
        email: currentUser.email,
        q: JSON.stringify({
          email: currentUser.email,
          from_date: getFirstDayOfWeek(now),
          to_date: getLastDayOfWeek(now),
        }),
      });
    }
  }, [currentUser]);

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.goals);
      setTotalItems(data.total);
    }
    if (isError && error) {
      toast.error(error?.data?.message);
    }
  }, [data, isSuccess, isError]);

  const onAddBtnClicked = (e) => {
    getGoalOptions(undefined, true);
    dispatch(toggleModal({ type: "add" }));
  };

  const onFormSubmit = (data) => {
    var newData = processData(data);
    if (state.modal.add) {
      assignGoal(newData);
    } else if (state.modal.edit) {
      updateUserGoalProgress({
        params: { id: state.modal.item.id },
        data: newData,
      });
    }
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(toggleModal({ type: "edit" }));
      reset();
    }
    if (isUpdateError && updateError) {
      toast.error(updateError?.data?.message);
    }
  }, [isUpdateError, isUpdateSuccess]);

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    // eslint-disable-next-line default-case
    switch (action) {
      case "remove":
        unAssignGoal({
          id: row.id,
        });
        break;
      case "details":
        dispatch(toggleModal({ type: "details", item: row }));
        break;
      case "edit":
        dispatch(toggleModal({ type: "edit", item: row }));
        reset({
          ...row,
          date: moment(row.date_time).toDate(),
          time: moment(row.date_time).toDate(),
        });
        break;
    }
  };

  const processData = (rawData) => {
    if (currentUser == null) {
      return null;
    }
    var data = { ...rawData };
    data.goal = {
      id: data.goal.value,
      name: data.goal.label,
    };
    data.email = currentUser.email;
    return data;
  };

  useEffect(() => {
    if (isAssignSuccess) {
      dispatch(toggleModal({ type: "add" }));
      reset(defaultValues);
    }
    if (isAssignError && assignError) {
      toast.error(assignError?.data?.message);
    }
  }, [isAssignSuccess, isAssignError]);

  useEffect(() => {
    if (isGetGoalSuccess && goalData?.goals) {
      let data = goalData.goals.map((e) => {
        return { value: e.id, label: e.name };
      });
      setGoalOptions(data);
    }
  }, [isGetGoalSuccess, isGetGoalError]);

  const loadUsersOptions = async (inputValue, callback) => {
    if (inputValue.length < 3) return;
    let response = await getAppUsers(
      { offset: 0, limit: 0, search_text: inputValue, is_pure: true },
      true
    ).unwrap();

    if (response.users) {
      const options = response.users.map((item) => {
        let label = "";
        if (item.user.first_name) {
          label += item.user.first_name;
        }
        if (item.user.last_name) {
          label += " " + item.user.last_name;
        }
        label += " - " + item.user.email;

        return { label: label, value: item.user };
      });
      callback(options);
    }
  };

  return (
    <>
      <Head title="Goal Tracking"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle page>Goal Tracking</BlockTitle>
            </BlockHeadContent>
          </BlockHead>
          <Block className="mb-4">
            <BlockBetween>
              <Block className="w-min-400px">
                <AsyncSelectField
                  onChangeFunc={(newValue) => {
                    setCurrentUser(newValue.value);
                  }}
                  loadOptions={async (inputValue, callback) => {
                    await loadUsersOptions(inputValue, callback);
                  }}
                  noOptionsMessage={(data) => {
                    if (data.inputValue.length >= 3) {
                      return "No results found";
                    }
                    return "Minimum 3 characters required";
                  }}
                  cacheOptions
                />
              </Block>
              <BlockHeadContent>
                {currentUser && (
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <Button
                      className="toggle btn-icon d-md-none"
                      color="primary"
                      onClick={onAddBtnClicked}
                    >
                      <Icon name="plus" />
                    </Button>
                    <Button
                      className="toggle d-none d-md-inline-flex"
                      color="primary"
                      onClick={onAddBtnClicked}
                    >
                      <Icon name="plus" />
                      <span>Assign Goal</span>
                    </Button>
                  </div>
                )}
              </BlockHeadContent>
            </BlockBetween>
          </Block>

          <PreviewCard>
            <ReactDataTable
              data={tableData}
              columns={goalTrackingColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              totalItems={totalItems}
              progressPending={isFetching}
              className="nk-tb-list"
              onRowClicked={onRowClicked}
              changePageState={changePageState}
              disableItemsPerPage={true}
            />
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.add || state.modal.edit}
          title={`${state.modal.add ? "Add" : "Edit"} Log`}
          toggle={() => {
            dispatch(onFormCancel());
            reset();
          }}
          backdrop={"static"}
          onModalCancel={() => {
            dispatch(onFormCancel());
            reset();
          }}
        >
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Row className="g-3">
              <SelectField
                name={"goal"}
                label={"Goal"}
                control={control}
                rules={{ required: "This field is required" }}
                options={goalOptions}
                isSearchable={false}
                isLoading={isGetGoalFetching}
                isDisabled={isGetGoalFetching}
                errors={errors}
                md="12"
              />
              <TextInput
                name={"target"}
                label={"Target"}
                type={"number"}
                caption={"Enter target value (number), e.g. 5 days a week"}
                required
                register={{
                  ...register("target", {
                    valueAsNumber: true,
                    required: "This field is required",
                  }),
                }}
                errors={errors}
                md="12"
              />
              <DatePickerInput
                name={"start_date"}
                label={"Start date"}
                placeholder={"dd/mm/yyyy"}
                dateFormat={"dd/MM/yyyy"}
                rules={{ required: "This field is required" }}
                control={control}
                errors={errors}
              />
              <DatePickerInput
                name={"end_date"}
                label={"End date"}
                placeholder={"dd/mm/yyyy"}
                dateFormat={"dd/MM/yyyy"}
                control={control}
                errors={errors}
              />

              <Col size="12">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isAssignLoading || isUpdateLoading}
                >
                  {(isAssignLoading || isUpdateLoading) && (
                    <Spinner type="grow" size="sm" />
                  )}
                  <span>{state.modal.add ? "Add" : "Edit"}</span>
                </Button>
              </Col>
            </Row>
          </form>
        </ModalComponent>
      </Content>
    </>
  );
};

export default AppGoalTracking;
