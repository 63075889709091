import { Button } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Components";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";

const ChatbotTrain = () => {
  return (
    <>
      <Head title="Train"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>
                  Training History{" "}
                  {/* <Icon
                    className="ml-2"
                    name="plus"
                    style={{ cursor: "pointer" }}
                  /> */}
                </BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle btn-icon d-md-none"
                    color="primary"
                    onClick={() => {}}
                  >
                    <Icon name="plus" />
                  </Button>
                  <Button
                    className="toggle d-none d-md-inline-flex"
                    color="primary"
                    onClick={() => {}}
                  >
                    <span>Train Bot</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
        </Block>
      </Content>
    </>
  );
};

export default ChatbotTrain;
