import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect, useEffect } from "react";
import ReactDataTable from "../../../components/table/ReactDataTable";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Col,
  TextareaInput,
  SelectField,
  ModalComponent,
  Row,
  DatePickerInput,
  TimePickerInput,
} from "../../../components/Components";
import { emotionalLogColumns } from "./DataColumns";
import {
  onChangePageState,
  toggleModal,
  onFormCancel,
} from "../../../redux/features/app-portal/logs/emotionalLogSlice";
import {
  useGetEmotionalLogsQuery,
  useCreateEmotionalLogsMutation,
  useUpdateEmotionalLogsMutation,
  useDeleteEmotionalLogsMutation,
} from "../../../redux/api/emotional-management/emotionalApi";
import { Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { AsyncSelectField } from "../../../components/form/SelectField";
import { useLazyGetAppUsersQuery } from "../../../redux/api/users/usersApi";
import { emotionTypeOptions } from "../../../constants";
import { toast } from "react-toastify";
import moment from "moment";

const AppEmotionalLog = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.emotionalLog);
  const { data, isFetching, isSuccess } = useGetEmotionalLogsQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
    search_text: state.searchText,
  });
  const [getAppUsers, { data: appUsers, isFetching: isGetAppUsersFetching }] =
    useLazyGetAppUsersQuery();
  const [
    createLog,
    {
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreateEmotionalLogsMutation();
  const [
    updateEmotionalLog,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateEmotionalLogsMutation();
  const [
    deleteEmotionLog,
    {
      data: deleteData,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
    },
  ] = useDeleteEmotionalLogsMutation();
  const defaultValues = {
    type: emotionTypeOptions[0],
    email: "",
    description: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm(defaultValues);
  const [tableData, setTableData] = useState(data?.emotionalLogs ?? []);
  const [totalItems, setTotalItems] = useState(data?.total ?? 0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.emotionalLogs);
      setTotalItems(data.total);
    }
  }, [data]);

  const onAddBtnClicked = (e) => {
    dispatch(toggleModal({ type: "add" }));
  };

  const onFormSubmit = (data) => {
    var newData = processData(data);
    if (state.modal.add) {
      createLog(newData);
    } else if (state.modal.edit) {
      updateEmotionalLog({
        params: { id: state.modal.item.id },
        data: newData,
      });
    }
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(toggleModal({ type: "edit" }));
      reset();
    }
    if (isUpdateError && updateError) {
      toast.error(updateError?.data?.message);
    }
  }, [isUpdateError, isUpdateSuccess]);

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    // eslint-disable-next-line default-case
    switch (action) {
      case "remove":
        deleteEmotionLog({
          ids: [row.id],
        });
        break;
      case "details":
        dispatch(toggleModal({ type: "details", item: row }));
        break;
      case "edit":
        dispatch(toggleModal({ type: "edit", item: row }));
        reset({
          ...row,
          type: {
            label: emotionTypeOptions.find((x) => x.value === row.type)?.label,
            value: row.type,
          },
          date: moment(row.date_time).toDate(),
          time: moment(row.date_time).toDate(),
        });
        break;
    }
  };

  const processData = (rawData) => {
    var data = { ...rawData };

    data.type = data.type.value;
    data.date_time =
      data.date && data.time
        ? new Date(
            data.date.getFullYear(),
            data.date.getMonth(),
            data.date.getDate(),
            data.time.getHours(),
            data.time.getMinutes()
          ).toISOString()
        : null;

    return data;
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(toggleModal({ type: "add" }));
      reset(defaultValues);
    }
    if (isCreateError && createError) {
      toast.error(createError?.data?.message);
    }
  }, [isCreateSuccess, isCreateError]);

  const loadUsersOptions = async (inputValue, callback) => {
    if (inputValue.length < 3) return;
    let response = await getAppUsers(
      { offset: 0, limit: 0, search_text: inputValue, is_pure: true },
      true
    ).unwrap();

    if (response.users) {
      const options = response.users.map((item) => {
        let label = "";
        if (item.user.first_name) {
          label += item.user.first_name;
        }
        if (item.user.last_name) {
          label += " " + item.user.last_name;
        }
        label += " - " + item.user.email;

        return { label: label, value: item.user.email };
      });
      callback(options);
    }
  };

  return (
    <>
      <Head title="Emotional log"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Emotional log</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle btn-icon d-md-none"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                  </Button>
                  <Button
                    className="toggle d-none d-md-inline-flex"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                    <span>Add log</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <ReactDataTable
              data={tableData}
              columns={emotionalLogColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              pagination
              totalItems={totalItems}
              progressPending={isFetching}
              className="nk-tb-list"
              selectableRows
              onRowClicked={onRowClicked}
              changePageState={changePageState}
            />
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.add || state.modal.edit}
          title={`${state.modal.add ? "Add" : "Edit"} Log`}
          toggle={() => {
            dispatch(onFormCancel());
            reset();
          }}
          backdrop={"static"}
          onModalCancel={() => {
            dispatch(onFormCancel());
            reset();
          }}
        >
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Row className="g-3">
              <Col size="6">
                <label className="form-label">
                  User <span className="required-asterisk">*</span>
                </label>
                <AsyncSelectField
                  defaultValue={
                    state.modal.add
                      ? null
                      : {
                          label: `${state.modal.item?.first_name} ${state.modal.item?.last_name} - ${state.modal.item?.email}`,
                          value: state.modal.item?.email,
                        }
                  }
                  onChangeFunc={(newValue) => {
                    setValue("email", newValue.value);
                  }}
                  loadOptions={async (inputValue, callback) => {
                    await loadUsersOptions(inputValue, callback);
                  }}
                  noOptionsMessage={(data) => {
                    if (data.inputValue.length >= 3) {
                      return "No results found";
                    }
                    return "Minimum 3 characters required";
                  }}
                  cacheOptions
                />
              </Col>
              <SelectField
                name={"type"}
                label={"Type"}
                control={control}
                rules={{ required: "This field is required" }}
                options={emotionTypeOptions}
                defaultValue={emotionTypeOptions[0]}
                errors={errors}
              />
              <TextareaInput
                name={"description"}
                label={"Description"}
                required
                register={{
                  ...register("description", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
                md="12"
              />
              <DatePickerInput
                name={"date"}
                label={"Date"}
                placeholder={"dd/mm/yyyy"}
                dateFormat={"dd/MM/yyyy"}
                rules={{ required: "This field is required" }}
                control={control}
                errors={errors}
              />
              <TimePickerInput
                name={"time"}
                label={"Time"}
                placeholder={"hh:mm"}
                dateFormat={"HH:mm"}
                timeFormat={"HH:mm"}
                control={control}
                rules={{ required: "This field is required" }}
                errors={errors}
              />

              <Col size="12">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isCreateLoading || isUpdateLoading}
                >
                  {(isCreateLoading || isUpdateLoading) && (
                    <Spinner type="grow" size="sm" />
                  )}
                  <span>{state.modal.add ? "Add" : "Edit"}</span>
                </Button>
              </Col>
            </Row>
          </form>
        </ModalComponent>
      </Content>
    </>
  );
};

export default AppEmotionalLog;
