import { useDispatch, useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  onChangePageState,
  toggleModal,
  onFormCancel,
  onChangeSearchText,
  onChangeClearSelectedRows,
  onSelectedRowsChange,
} from "../../redux/features/emotionalLogsSlice";
import {
  useGetEmotionalLogsQuery,
  useCreateEmotionalLogsMutation,
  useDeleteEmotionalLogsMutation,
  useUpdateEmotionalLogsMutation,
  useLazyGetEmotionTypesQuery,
} from "../../redux/api/emotional-management/emotionalApi";
import ReactDataTable from "../../components/table/ReactDataTable";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Row,
  Col,
  ModalComponent,
  TextInput,
  TextareaInput,
  SelectField,
} from "../../components/Components";
import { appEmotionalLogsColumns, emotionalLogsColumns } from "./DataColumns";
import { useForm } from "react-hook-form";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import moment from "moment";

const EmotionalLogs = ({ isAppPortal = false }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.emotionalLogs);
  const { data, isFetching, isSuccess } = useGetEmotionalLogsQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
    search_text: state.searchText,
  });
  const defaultValues = {
    defaultValues: {
      email: "",
      type: "",
      description: "",
    },
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm(defaultValues);
  const [
    getEmotionTypes,
    {
      data: emotionTypesData,
      isSuccess: isGetEmotionTypesSuccess,
      isFetching: isGetEmotionTypesFetching,
      isError: isGetEmotionTypesError,
    },
  ] = useLazyGetEmotionTypesQuery({
    offset: 0,
    limit: 0,
  });
  const [
    createEmotionalLog,
    {
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreateEmotionalLogsMutation();
  const [
    updateEmotionalLog,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateEmotionalLogsMutation();
  const [
    deleteEmotionalLog,
    {
      data: deleteData,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
    },
  ] = useDeleteEmotionalLogsMutation();
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.emotionalLogs);
      setTotalItems(data.total);
    }
  }, [data]);

  const onAddBtnClicked = (e) => {
    dispatch(toggleModal({ type: "add" }));
    getEmotionTypes(undefined, true);
    reset(defaultValues);
  };

  const onFormSubmit = (data) => {
    data.type = data.type.value;

    if (state.modal.add) {
      createEmotionalLog(data);
    } else if (state.modal.edit) {
      updateEmotionalLog({
        params: { id: state.modal.item.id },
        data: data,
      });
    }
  };

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "remove":
        deleteEmotionalLog({
          ids: [row.id],
        });
        break;
      case "details":
        dispatch(toggleModal({ type: "details", item: row }));
        break;
      case "edit":
        getEmotionTypes(undefined, true);
        dispatch(toggleModal({ type: "edit", item: row }));
        reset({
          ...row,
          type: {
            label: row.type,
            value: row.type,
          },
        });
        break;
    }
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(toggleModal({ type: "add" }));
      reset();
    }
    if (isCreateError && createError) {
      toast.error(createError?.data?.message);
    }
  }, [isCreateSuccess, isCreateError]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(toggleModal({ type: "edit" }));
      reset();
    }
    if (isUpdateError && updateError) {
      toast.error(updateError?.data?.message);
    }
  }, [isUpdateError, isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      if (state.modal.removeItems) {
        dispatch(toggleModal({ type: "removeItems" }));
        dispatch(onChangeClearSelectedRows(!state.clearSelectedRows));
      } else {
        if (deleteData && deleteData.data) {
          let newSelectedRows = state.selectedRows.filter(
            (row) => row.id !== deleteData.data[0]
          );
          dispatch(onSelectedRowsChange(newSelectedRows));
        }
      }
    }
    if (isDeleteError && deleteError) {
      toast.error(deleteError?.data?.message);
    }
  }, [isDeleteSuccess, isDeleteError]);

  const handleSelectedRowsChange = ({ selectedRows }) => {
    dispatch(onSelectedRowsChange(selectedRows));
  };

  const handleApplySelectedRows = (row) => {
    return (
      state.selectedRows?.filter((selectedRow) => selectedRow.id === row.id)
        .length > 0
    );
  };

  return (
    <>
      <Head title="Emotional Logs" />
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Emotional Logs</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle btn-icon d-md-none"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                  </Button>
                  <Button
                    className="toggle d-none d-md-inline-flex"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                    <span>Add Emotional Log</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <ReactDataTable
              data={tableData}
              columns={isAppPortal ? appEmotionalLogsColumns : emotionalLogsColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              pagination
              totalItems={totalItems}
              progressPending={isFetching}
              className="nk-tb-list"
              selectableRows
              changePageState={changePageState}
              onRowClicked={onRowClicked}
              placeholderSearchText={"Search by email"}
              searchFunc={(searchText) => {
                dispatch(onChangeSearchText(searchText));
              }}
              onSelectedRowsChange={handleSelectedRowsChange}
              clearSelectedRows={state.clearSelectedRows}
              // selectableRowSelected={handleApplySelectedRows}
            />
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.details}
          toggle={() => dispatch(toggleModal({ type: "details" }))}
          title={"Emotional Log Details"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {state.modal.item && (
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text">Email</span>
                <span className="caption-text">{state.modal.item.email}</span>
              </Col>

              <Col lg={6}>
                <span className="sub-text">Type</span>
                <span className="caption-text">{state.modal.item.type}</span>
              </Col>
              <Col lg={12}>
                <span className="sub-text">Description</span>
                <span className="caption-text">
                  {state.modal.item.description}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Created Time</span>
                <span className="caption-text">
                  {moment(state.modal.item.created_time).format(
                    "DD-MM-YYYY, hh:mm:ss a"
                  )}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Last Updated Time</span>
                <span className="caption-text">
                  {moment(state.modal.item.last_updated_time).format(
                    "DD-MM-YYYY, hh:mm:ss a"
                  )}
                </span>
              </Col>
            </Row>
          )}
        </ModalComponent>

        <ModalComponent
          isOpen={state.modal.add || state.modal.edit}
          title={`${state.modal.add ? "Add" : "Edit"} Emotional Log`}
          onModalCancel={() => {
            dispatch(onFormCancel());
            reset();
          }}
          toggle={() => {
            dispatch(onFormCancel());
            reset();
          }}
          backdrop={"static"}
        >
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Row className="g-3">
              <TextInput
                name={"email"}
                label={"Email"}
                required
                register={{
                  ...register("email", {
                    required: "This field is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  }),
                }}
                errors={errors}
              />
              <SelectField
                name={"type"}
                label={"Type"}
                control={control}
                rules={{ required: "This field is required" }}
                options={
                  emotionTypesData?.emotionTypes.map((item) => ({
                    label: item.type,
                    value: item.type,
                  })) ?? {}
                }
                isLoading={isGetEmotionTypesFetching}
                isDisabled={isGetEmotionTypesFetching}
                errors={errors}
              />
              <TextareaInput
                name={"description"}
                label={"Description"}
                required
                register={{
                  ...register("description", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
                md={12}
              />
              <Col size="12">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isCreateLoading || isUpdateLoading}
                >
                  {(isCreateLoading || isUpdateLoading) && (
                    <Spinner type="grow" size="sm" />
                  )}
                  <span>{state.modal.add ? "Add" : "Edit"}</span>
                </Button>
              </Col>
            </Row>
          </form>
        </ModalComponent>

        <ModalComponent
          isOpen={state.modal.removeItems}
          backdrop={"static"}
          title={"Are you sure you want to remove selected items?"}
          onModalCancel={() => {
            dispatch(toggleModal({ type: "removeItems" }));
          }}
          disableCloseBtn={true}
          size={"xs"}
        >
          <Row className="gy-3">
            <Col size="12">
              <Button
                color="primary"
                type="submit"
                disabled={isDeleteLoading}
                onClick={() => {
                  deleteEmotionalLog({
                    ids: state.selectedRows.map((row) => row.id),
                  });
                }}
              >
                {isDeleteLoading && <Spinner type="grow" size="sm" />}
                <span>Yes</span>
              </Button>
              <Button
                className="ml-3"
                color="light"
                onClick={() => {
                  dispatch(toggleModal({ type: "removeItems" }));
                }}
              >
                <span>No</span>
              </Button>
            </Col>
          </Row>
        </ModalComponent>
      </Content>
    </>
  );
};
export default EmotionalLogs;
