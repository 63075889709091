import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
  Icon,
  Row,
  UserAvatar,
} from "../../components/Components";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import SimpleBar from "simplebar-react";
import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { useSubmitBotMessagesMutation } from "../../redux/api/chatbot/chatbotApi";

const TestBot = () => {
  const [session, setSession] = useState("");
  const [inputText, setInputText] = useState("");
  const messagesEndRef = useRef(null);
  const [chatData, setChatData] = useState([]);
  const [
    submitMessage,
    {
      data: submitMessageResponse,
      isLoading: isSubmitLoading,
      isError: isSubmitError,
      isSuccess: isSubmitSuccess,
      error: submitError,
    },
  ] = useSubmitBotMessagesMutation();

  const onTextSubmit = (e) => {
    if (inputText === "" || !session) return;

    submitMessage({
      session_id: session,
      message: inputText,
    });
    setChatData((prev) => [
      ...prev,
      {
        me: true,
        message: inputText,
      },
    ]);
    setInputText("");
  };

  useEffect(() => {
    if (isSubmitSuccess) {
      setChatData((prev) => [
          ...prev,
          ...submitMessageResponse.messages.map((item) => ({
            me: false,
            message: item.text,
          })),
      ]);
    }
  }, [isSubmitSuccess]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      const scrollHeight = messagesEndRef.current.scrollHeight;
      const height = messagesEndRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  const onInputChange = (e) => {
    setInputText(e.target.value);
  };

  const chatBodyClass = classNames({
    "nk-chat-body": true,
    "show-chat": true,
  });

  return (
    <>
      <Head title="TestBot"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle page>Test Bot</BlockTitle>
            </BlockHeadContent>
          </BlockHead>
        </Block>

        <Block>
          <Row className="g-gs">
            <Col xxl={3} md={3}>
              <BlockHead>
                <BlockTitle tag="h5">
                  Chat Histories
                  <Icon
                    className="ml-2"
                    name="plus"
                    style={{ cursor: "pointer" }}
                  />
                </BlockTitle>
              </BlockHead>
            </Col>

            <Col xxl={7} md={7} style={{ minHeight: "75vh" }}>
              <div
                className={chatBodyClass}
                style={{
                  height: "100%",
                  pointerEvents: session ? "auto" : "none",
                }}
              >
                <div className="nk-chat-head">
                  <ul className="nk-chat-head-info">
                    <li
                      className="nk-chat-body-close"
                      //   onClick={() => setMobileView(false)}
                    >
                      <a
                        href="#hide-chat"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                        className="btn btn-icon btn-trigger nk-chat-hide ms-n1"
                      >
                        <Icon name="arrow-left"></Icon>
                      </a>
                    </li>
                    <li className="nk-chat-head-user">
                      <div className="user-card">
                        <div className="user-info">
                          <div className="lead-text">Test</div>
                          <div className="sub-text">
                            <span className="d-none d-sm-inline me-1">
                              Active
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <SimpleBar
                  className="nk-chat-panel"
                  scrollableNodeProps={{ ref: messagesEndRef }}
                >
                  {chatData.map((item, idx) => {
                    if (item.me) {
                      return <MeChat key={`chat-data-${session}-${idx}`} message={item.message}></MeChat>;
                    }
                    return <YouChat key={`chat-data-${session}-${idx}`} message={item.message}></YouChat>;
                  })}
                  {/* {Uchat.convo.map((item, idx) => {
                    if (item.me) {
                      return (
                        <MeChat
                          key={idx}
                          item={item}
                          chat={Uchat}
                        //   onRemoveMessage={onRemoveMessage}
                        ></MeChat>
                      );
                    } else if (item.meta) {
                      return (
                        <MetaChat
                          key={idx}
                          item={item.meta.metaText}
                        ></MetaChat>
                      );
                    } else {
                      return (
                        <YouChat key={idx} item={item} chat={Uchat}></YouChat>
                      );
                    }
                  })} */}
                </SimpleBar>
                <div className="nk-chat-editor">
                  <div className="nk-chat-editor-form">
                    <div className="form-control-wrap">
                      <textarea
                        className="form-control form-control-simple no-resize"
                        rows="1"
                        id="default-textarea"
                        onChange={(e) => onInputChange(e)}
                        value={inputText}
                        placeholder="Type your message..."
                        onKeyDown={(e) => {
                          if (e.code === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            onTextSubmit(e);
                          }
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <ul className="nk-chat-editor-tools g-2">
                    <li>
                      <Button
                        color="primary"
                        onClick={(e) => onTextSubmit(e)}
                        className="btn-round btn-icon"
                      >
                        <Icon name="send-alt"></Icon>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
              {!session && (
                <div>
                  <div className="overlay"></div>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 2000,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "rgba(255,255,255,0.8)",
                        color: "#111",
                      }}
                      onClick={() => {
                        setSession(uuidv4());
                      }}
                    >
                      Start new session
                    </Button>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Block>
      </Content>
    </>
  );
};

export default TestBot;

const MeChat = ({ item, chat, onRemoveMessage, message }) => {
  return (
    <div className="chat is-me">
      <div className="chat-content">
        <div className="chat-bubbles">
          <div className="chat-bubble">
            {message === "deleted" ? (
              <div className="chat-msg border bg-white text-muted">
                Message has been deleted
              </div>
            ) : (
              <React.Fragment>
                <div className={`chat-msg bg-purple`}>{message}</div>
                {/* <ul className="chat-msg-more">
                      <li className="d-none d-sm-block">
                        <a
                          href="#delete"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onRemoveMessage(idx, item.id);
                          }}
                          className="btn btn-icon btn-sm btn-trigger"
                        >
                          <Icon name="trash-fill"></Icon>
                        </a>
                      </li>
                    </ul> */}
              </React.Fragment>
            )}
          </div>
        </div>
        {/* <ul className="chat-meta">
          <li>Abu Bin Ishtiyak</li>
          <li>{item.date}</li>
        </ul> */}
      </div>
    </div>
  );
};

const YouChat = ({ message }) => {
  return (
    <div className="chat is-you">
      <div className="chat-avatar">
        <UserAvatar icon="user-alt" className="sm" />
      </div>
      <div className="chat-content">
        <div className="chat-bubbles">
          <div className="chat-bubble">
            <div className="chat-msg">{message}</div>
          </div>
        </div>
        {/* <ul className="chat-meta">
          <li>{chat.name}</li>
          <li>{item.date}</li>
        </ul> */}
      </div>
    </div>
  );
};
