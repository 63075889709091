import { api } from "../api";

export const chatbot = api.injectEndpoints({
  endpoints: (builder) => ({
    getIntents: builder.query({
      query: (params) => {
        return {
          url: "bot/v1/intents",
          params: params,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, data) => {
        return [{ type: "Intents" }];
      },
    }),
    createIntent: builder.mutation({
      query: (data) => ({
        url: "bot/v1/intents",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Intents"],
    }),
    getIntentExamples: builder.query({
      query: (params) => {
        return {
          url: "bot/v1/intents-examples",
          params: params,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, data) => [
        { type: "IntentExamples", id: data.intent_id },
      ],
    }),
    createIntentExamples: builder.mutation({
      query: (data) => ({
        url: "bot/v1/intents-examples",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "IntentExamples", id: data.intent_id },
      ],
    }),
    getUtterances: builder.query({
      query: (params) => {
        return {
          url: "bot/v1/utterances",
          params: params,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, data) => {
        return [{ type: "Utterances" }];
      },
    }),
    createUtterance: builder.mutation({
      query: (data) => ({
        url: "bot/v1/utterances",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Utterances"],
    }),
    getStories: builder.query({
      query: (params) => {
        return {
          url: "bot/v1/stories",
          params: params,
        };
      },
      transformResponse: (response, meta, params) => {
        if (params?.id) {
          return response.data && response.data[0];
        }

        return response.data;
      },
      providesTags: ["Stories"],
    }),
    createStory: builder.mutation({
      query: (data) => ({
        url: "bot/v1/stories",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Stories"],
    }),
    getUtteranceResponses: builder.query({
      query: (params) => {
        return {
          url: "bot/v1/utterances-responses",
          params: params,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, data) => [
        { type: "UtteranceResponses", id: data.utterance_id },
      ],
    }),
    createUtteranceResponses: builder.mutation({
      query: (data) => ({
        url: "bot/v1/utterances-responses",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "UtteranceResponses", id: data.utterance_id },
      ],
    }),
    submitBotMessages: builder.mutation({
      query: (data) => ({
        url: "bot/v1/messages",
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => {
        return response.data[0];
      },
    }),
  }),
});

export const {
  useGetIntentsQuery,
  useLazyGetIntentsQuery,
  useCreateIntentMutation,
  useGetIntentExamplesQuery,
  useLazyGetIntentExamplesQuery,
  useCreateIntentExamplesMutation,
  useGetUtterancesQuery,
  useLazyGetUtterancesQuery,
  useLazyGetUtteranceResponsesQuery,
  useCreateUtteranceMutation,
  useCreateUtteranceResponsesMutation,
  useSubmitBotMessagesMutation,
  useGetStoriesQuery,
  useLazyGetStoriesQuery,
  useCreateStoryMutation,
} = chatbot;
