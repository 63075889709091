import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect, useEffect } from "react";
import ReactDataTable from "../../../components/table/ReactDataTable";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  SelectField,
  Col,
  Row,
  ModalComponent,
  TextInput,
  DatePickerInput,
  CheckboxInput,
} from "../../../components/Components";
import { professionalToUserColumns } from "./DataColumns";
import {
  onChangePageState,
  toggleModal,
  onFormCancel,
} from "../../../redux/features/app-portal/support/professionalToUserSlice";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  useCreateProScheduleMutation,
  useGetProSchedulesQuery,
  useLazyGetEmploymentDetailsQuery,
} from "../../../redux/api/employment-management/employmentApi";
import {
  purposeOptions,
  workTypeOptions,
  workingDaysOptions,
} from "../../../constants";
import { useLazyGetAppUsersQuery } from "../../../redux/api/users/usersApi";
import { AsyncSelectField } from "../../../components/form/SelectField";
import {
  useCreateUserMutation,
  useLazyGetUsersQuery,
} from "../../../redux/api/users/usersApi";

const ProfessionalToUser = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.professionalToUser);
  const defaultValue = {
    purpose: purposeOptions[0],
    email: "",
    employment_email: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: defaultValue,
  });
  const userEmailField = watch("employment_email");
  const purposeField = watch("purpose");
  const { data, isFetching, isSuccess } = useGetProSchedulesQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
  });
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [
    createProSchedule,
    {
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreateProScheduleMutation();
  const [getAppUsers, { data: appUsers, isFetching: isGetAppUsersFetching }] =
    useLazyGetAppUsersQuery();
  const [
    getProfessionals,
    { data: professionals, isFetching: isGetProfessionalsFetching },
  ] = useLazyGetUsersQuery();
  const [
    getEmploymentDetails,
    {
      data: employmentData,
      isFetching: isGetEmploymentFetching,
      isSuccess: isGetEmploymentSuccess,
    },
  ] = useLazyGetEmploymentDetailsQuery();
  const [employmentOptions, setEmploymentOptions] = useState([]);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.proSchedules);
      setTotalItems(data.total);
    }
  }, [data]);

  useEffect(() => {
    if (userEmailField) {
      getEmploymentDetails({ email: userEmailField }, true);
    }
  }, [userEmailField]);

  useEffect(() => {
    if (isGetEmploymentSuccess && employmentData) {
      // setValue("employment_email", employmentData.employments[0].email);
      var options = employmentData.employments.map((item, index) => {
        var t = workTypeOptions.find(
          (option) => option.value === item.work_type
        );
        return {
          label: `${item.employer} - ${t?.label ?? "Unknown work type"}`,
          value: item.id,
        };
      });
      setEmploymentOptions(options);
      setValue("employment", options[0]);
    }
  }, [isGetEmploymentSuccess]);

  const onFormSubmit = (data) => {
    var newData = processData(data);
    createProSchedule(newData);
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(toggleModal({ type: "add" }));
      reset(defaultValue);
    }
    if (isCreateError && createError) {
      toast.error(createError?.data?.message);
    }
  }, [isCreateSuccess, isCreateError]);

  const onAddBtnClicked = (e) => {
    dispatch(toggleModal({ type: "add" }));
  };

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "remove":
        //dispatch delete
        break;
      case "details":
        dispatch(toggleModal({ type: "details", item: row }));
        break;
    }
  };

  const processData = (rawData) => {
    var data = { ...rawData };

    data.purpose = data.purpose.value;
    data.start_date = data.start_date
      ? new Date(data.start_date).toISOString()
      : null;
    data.end_date = data.end_date
      ? new Date(data.end_date).toISOString()
      : null;

    return data;
  };

  const loadUsersOptions = async (inputValue, callback) => {
    if (inputValue.length < 3) return;
    let response = await getAppUsers(
      { offset: 0, limit: 0, search_text: inputValue, is_pure: true },
      true
    ).unwrap();

    if (response.users) {
      const options = response.users.map((item) => {
        let label = "";
        if (item.user.first_name) {
          label += item.user.first_name;
        }
        if (item.user.last_name) {
          label += " " + item.user.last_name;
        }
        label += " - " + item.user.email;

        return { label: label, value: item.user.email };
      });
      callback(options);
    }
  };

  const loadProfessionalsOptions = async (inputValue, callback) => {
    if (inputValue.length < 3) return;
    let response = await getProfessionals(
      {
        offset: state.offset,
        limit: state.itemsPerPage,
        total: true,
        reverse: true,
        is_app_portal: true,
        exclude_types: "CANDIDATE, CAREGIVER",
        search_text: inputValue,
        is_pure: true,
      },
      true
    ).unwrap();

    if (response.users) {
      const options = response.users.map((item) => {
        let label = "";
        if (item.user.first_name) {
          label += item.user.first_name;
        }
        if (item.user.last_name) {
          label += " " + item.user.last_name;
        }
        label += " - " + item.user.email;

        return { label: label, value: item.user.email };
      });
      callback(options);
    }
  };

  return (
    <>
      <Head title="Professional to user" />
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Professional to user</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle btn-icon d-md-none"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                  </Button>
                  <Button
                    className="toggle d-none d-md-inline-flex"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                    <span>Link Pro to user</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <ReactDataTable
              data={tableData}
              columns={professionalToUserColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              pagination
              totalItems={totalItems}
              progressPending={isFetching}
              className="nk-tb-list"
              selectableRows
              changePageState={changePageState}
              onRowClicked={onRowClicked}
            />
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.details}
          toggle={() => dispatch(toggleModal({ type: "details" }))}
          title={"Pro Schedule Details"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {state.modal.item && (
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text">Pro Schedule Id</span>
                <span className="caption-text">{state.modal.item.id}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Pro Email</span>
                <span className="caption-text">{state.modal.item.email}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">PWD Email</span>
                <span className="caption-text">
                  {state.modal.item.employment_email}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Created Time</span>
                <span className="caption-text">
                  {state.modal.item.created_time}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Last Updated Time</span>
                <span className="caption-text">
                  {state.modal.item.last_updated_time}
                </span>
              </Col>
            </Row>
          )}
        </ModalComponent>

        <ModalComponent
          isOpen={state.modal.add}
          toggle={() => dispatch(onFormCancel())}
          backdrop={"static"}
          title={"Add Pro Schedule"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {/* <Alert className="alert-icon" color="danger" isOpen={isCreateError}>
            <Icon name="cross-circle" />
            <strong>Create failed</strong>!{" "}
            {createError?.data.message ?? "There is some technical issues."}
          </Alert> */}
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Row className="g-3">
              <Col size="12">
                <label className="form-label">
                  User <span className="required-asterisk">*</span>
                </label>
                <AsyncSelectField
                  onChangeFunc={(newValue) => {
                    setValue("employment_email", newValue.value);
                  }}
                  loadOptions={async (inputValue, callback) => {
                    await loadUsersOptions(inputValue, callback);
                  }}
                  noOptionsMessage={(data) => {
                    if (data.inputValue.length >= 3) {
                      return "No results found";
                    }
                    return "Minimum 3 characters required";
                  }}
                  cacheOptions
                />
              </Col>
              <Col size="12">
                <label className="form-label">
                  Professional <span className="required-asterisk">*</span>
                </label>
                <AsyncSelectField
                  onChangeFunc={(newValue) => {
                    setValue("email", newValue.value);
                  }}
                  loadOptions={async (inputValue, callback) => {
                    await loadProfessionalsOptions(inputValue, callback);
                  }}
                  noOptionsMessage={(data) => {
                    if (data.inputValue.length >= 3) {
                      return "No results found";
                    }
                    return "Minimum 3 characters required";
                  }}
                  cacheOptions
                />
              </Col>
              <SelectField
                name={"purpose"}
                label={"Purpose"}
                control={control}
                rules={{ required: "This field is required" }}
                options={purposeOptions}
                defaultValue={purposeOptions[0]}
                errors={errors}
              />
              <SelectField
                name={"employment"}
                label={"Employment"}
                control={control}
                placeholder={
                  purposeField.value !== purposeOptions[0].value
                    ? "Only needed if purpose is 'Employment'"
                    : !userEmailField
                    ? "Pick user first"
                    : ""
                }
                options={employmentOptions}
                errors={errors}
                isDisabled={
                  !userEmailField ||
                  purposeField.value !== purposeOptions[0].value ||
                  isGetEmploymentFetching
                }
              />
              <CheckboxInput
                name={"is_chat_enabled"}
                label={"Enable Chat?"}
                register={{
                  ...register("is_chat_enabled"),
                }}
                errors={errors}
                md="12"
              />
              <DatePickerInput
                name={"start_date"}
                label={"Start date"}
                placeholder={"dd/mm/yyyy"}
                dateFormat={"dd/MM/yyyy"}
                control={control}
                errors={errors}
              />
              <DatePickerInput
                name={"end_date"}
                label={"End date"}
                placeholder={"dd/mm/yyyy"}
                dateFormat={"dd/MM/yyyy"}
                control={control}
                errors={errors}
              />

              <Col size="12">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isCreateLoading}
                >
                  {isCreateLoading && <Spinner type="grow" size="sm" />}
                  <span>Add</span>
                </Button>
              </Col>
            </Row>
          </form>
        </ModalComponent>
      </Content>
    </>
  );
};

export default ProfessionalToUser;
