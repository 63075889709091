import React, { useState, useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { menu, appPortalMenu} from "./MenuData";
import { Icon } from "../../components/Components";
import { useSelector } from "react-redux";
import { appPortalProductionHostname, appPortalStagingHostname, stagingHostname } from "../../constants";

const MenuHeading = ({ heading }) => {
  return (
    <li className="nk-menu-heading">
      <h6 className="overline-title text-primary-alt">{heading}</h6>
    </li>
  );
};

const MenuItem = ({
  icon,
  link,
  text,
  sub,
  subPanel,
  panel,
  newTab,
  sidebarToggle,
  badge,
  ...props
}) => {
  let currentUrl;
  const { user } = useSelector((state) => state.auth);

  const toggleActionSidebar = (e) => {
    if (!sub && !newTab) {
      sidebarToggle(e);
    }
  };

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }

  const menuHeight = (el) => {
    var totalHeight = [];
    for (var i = 0; i < el.length; i++) {
      var margin =
        parseInt(window.getComputedStyle(el[i]).marginTop.slice(0, -2)) +
        parseInt(window.getComputedStyle(el[i]).marginBottom.slice(0, -2));
      var padding =
        parseInt(window.getComputedStyle(el[i]).paddingTop.slice(0, -2)) +
        parseInt(window.getComputedStyle(el[i]).paddingBottom.slice(0, -2));
      var height = el[i].clientHeight + margin + padding;
      totalHeight.push(height);
    }
    totalHeight = totalHeight.reduce((sum, value) => (sum += value));
    return totalHeight;
  };

  // const makeParentActive = (el, childHeight) => {
  //   let element = el.parentElement.parentElement.parentElement;
  //   let wrap = el.parentElement.parentElement;
  //   if (element.classList[0] === "nk-menu-item") {
  //     element.classList.add("active");
  //     const subMenuHeight = menuHeight(el.parentNode.children);
  //     wrap.style.height = subMenuHeight + childHeight - 50 + "px";
  //     makeParentActive(element);
  //   }
  // };

  // useEffect(() => {
  //   var element = document.getElementsByClassName(
  //     "nk-menu-item active current-page"
  //   );
  //   var arrayElement = [...element];

  //   arrayElement.forEach((dom) => {
  //     if (
  //       dom.parentElement.parentElement.parentElement.classList[0] ===
  //       "nk-menu-item"
  //     ) {
  //       dom.parentElement.parentElement.parentElement.classList.add("active");
  //       const subMenuHeight = menuHeight(dom.parentNode.children);
  //       dom.parentElement.parentElement.style.height = subMenuHeight + "px";
  //       makeParentActive(
  //         dom.parentElement.parentElement.parentElement,
  //         subMenuHeight
  //       );
  //     }
  //   });
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const menuToggle = (e) => {
    e.preventDefault();
    var self = e.target.closest(".nk-menu-toggle");
    var parent = self.parentElement;
    var subMenu = self.nextSibling;
    var subMenuItem = subMenu.childNodes;
    var parentSiblings = parent.parentElement.childNodes;
    var parentMenu = parent.closest(".nk-menu-wrap");
    //For Sub Menu Height
    var subMenuHeight = menuHeight(subMenuItem);
    // Get parent elements
    const getParents = (el, parentSelector) => {
      parentSelector = document.querySelector(".nk-menu");
      if (parentSelector === undefined) {
        parentSelector = document;
      }
      var parents = [];
      var p = el.parentNode;
      while (p !== parentSelector) {
        var o = p;
        parents.push(o);
        p = o.parentNode;
      }
      parents.push(parentSelector);
      return parents;
    };
    var parentMenus = getParents(self);
    if (!parent.classList.contains("active")) {
      // For Parent Siblings
      for (var j = 0; j < parentSiblings.length; j++) {
        parentSiblings[j].classList.remove("active");
        if (typeof parentSiblings[j].childNodes[1] !== "undefined") {
          parentSiblings[j].childNodes[1].style.height = 0;
        }
      }
      if (parentMenu !== null) {
        if (!parentMenu.classList.contains("sub-opened")) {
          parentMenu.classList.add("sub-opened");

          for (var l = 0; l < parentMenus.length; l++) {
            if (typeof parentMenus !== "undefined") {
              if (parentMenus[l].classList.contains("nk-menu-wrap")) {
                parentMenus[l].style.height =
                  subMenuHeight + parentMenus[l].clientHeight + "px";
              }
            }
          }
        }
      }
      // For Current Element
      parent.classList.add("active");
      subMenu.style.height = subMenuHeight + "px";
    } else {
      parent.classList.remove("active");
      if (parentMenu !== null) {
        parentMenu.classList.remove("sub-opened");
        for (var k = 0; k < parentMenus.length; k++) {
          if (typeof parentMenus !== "undefined") {
            if (parentMenus[k].classList.contains("nk-menu-wrap")) {
              parentMenus[k].style.height =
                parentMenus[k].clientHeight - subMenuHeight + "px";
            }
          }
        }
      }
      subMenu.style.height = 0;
    }
  };

  const menuItemClass = classNames({
    "nk-menu-item": true,
    "has-sub": sub,
    "active current-page": currentUrl === process.env.PUBLIC_URL + link,
  });

  if (user != null) {
    if (user.role_name !== "ADMIN") {
      if (sub != null) {
        let count = 0;
        for (let i = 0; i < sub.length; i++) {
          if (
            user.menu_data_list.find((menu) => menu.menu_url === sub[i].link)
          ) {
            count++;
          }
        }
        if (count === 0) {
          return null;
        }
      }
    }
  } else {
    return null;
  }

  return (
    <li className={menuItemClass} onClick={(e) => toggleActionSidebar(e)}>
      {newTab ? (
        <Link
          to={`${process.env.PUBLIC_URL + link}`}
          target="_blank"
          rel="noopener noreferrer"
          className="nk-menu-link"
        >
          {icon ? (
            <span className="nk-menu-icon">
              <Icon name={icon} />
            </span>
          ) : null}
          <span className="nk-menu-text">{text}</span>
        </Link>
      ) : (
        <NavLink
          to={`${process.env.PUBLIC_URL + link}`}
          className={`nk-menu-link${sub ? " nk-menu-toggle" : ""}`}
          onClick={sub ? menuToggle : null}
        >
          {icon ? (
            <span className="nk-menu-icon">
              <Icon name={icon} />
            </span>
          ) : null}
          <span className="nk-menu-text mr-3">{text}</span>
          {badge && <span className="nk-menu-badge">{badge}</span>}
        </NavLink>
      )}
      {sub ? (
        <div className="nk-menu-wrap">
          <MenuSub sub={sub} sidebarToggle={sidebarToggle} />
        </div>
      ) : null}
    </li>
  );
};

const PanelItem = ({
  icon,
  link,
  text,
  subPanel,
  index,
  data,
  setMenuData,
  ...props
}) => {
  const menuItemClass = classNames({
    "nk-menu-item": true,
  });
  const location = useLocation();
  var isAppPortal = location.pathname.startsWith("/app");

  var lmenu = menu;
  const hostname = window.location.hostname;
  if (hostname === appPortalProductionHostname || hostname === appPortalStagingHostname || isAppPortal) {
    lmenu = appPortalMenu;
  }

  if (data === lmenu) {
    return (
      <li className={menuItemClass}>
        <Link
          to={`${process.env.PUBLIC_URL}${link}`}
          className="nk-menu-link"
          onClick={() => setMenuData([lmenu[index]])}
        >
          {icon ? (
            <span className="nk-menu-icon">
              <Icon name={icon} />
            </span>
          ) : null}
          <span className="nk-menu-text">{text}</span>
          <span className="nk-menu-badge">HOT</span>
        </Link>
      </li>
    );
  } else {
    return (
      <React.Fragment>
        {subPanel.map((item) => (
          <MenuItem
            key={item.text}
            link={item.link}
            icon={item.icon}
            text={item.text}
            sub={item.subMenu}
            badge={item.badge}
          />
        ))}
        <MenuHeading heading="Return to" />
        <li className={menuItemClass}>
          <Link
            to={`${process.env.PUBLIC_URL}/`}
            className="nk-menu-link"
            onClick={() => setMenuData(lmenu)}
          >
            <span className="nk-menu-icon">
              <Icon name="dashlite-alt" />
            </span>
            <span className="nk-menu-text">Main Dashboard</span>
          </Link>
        </li>
        <li className={menuItemClass}>
          <Link
            to={`${process.env.PUBLIC_URL}/`}
            className="nk-menu-link"
            onClick={() => setMenuData(lmenu)}
          >
            <span className="nk-menu-icon">
              <Icon name="layers-fill" />
            </span>
            <span className="nk-menu-text">All Components</span>
          </Link>
        </li>
      </React.Fragment>
    );
  }
};

const MenuSub = ({ icon, link, text, sub, sidebarToggle, ...props }) => {
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  var isAppPortal = location.pathname.startsWith("/app");

  var lmenu = menu;
  const hostname = window.location.hostname;
  if (hostname === appPortalProductionHostname || hostname === appPortalStagingHostname || isAppPortal) {
    lmenu = appPortalMenu;
  }

  return (
    <ul className="nk-menu-sub" style={props.style}>
      {user != null &&
        sub.map((item) => {
          if (user.role_name !== "ADMIN") {
            if (
              item.link &&
              !user.menu_data_list.find((lmenu) => lmenu.menu_url === item.link)
            ) {
              return null;
            }
          }

          return (
            <MenuItem
              link={item.link}
              icon={item.icon}
              text={item.text}
              sub={item.subMenu}
              key={item.text}
              newTab={item.newTab}
              badge={item.badge}
              sidebarToggle={sidebarToggle}
            />
          );
        })}
    </ul>
  );
};

const Menu = ({ sidebarToggle }) => {
  const location = useLocation();
  var isAppPortal = location.pathname.startsWith("/app");

  var lmenu = menu;
  const hostname = window.location.hostname;
  if (hostname === appPortalProductionHostname || hostname === appPortalStagingHostname || isAppPortal) {
    lmenu = appPortalMenu;
  }

  const [data, setMenuData] = useState(lmenu);

  useEffect(() => {
    data.forEach((item, index) => {
      if (item.panel) {
        let found = item.subPanel.find(
          (sPanel) =>
            process.env.PUBLIC_URL + sPanel.link === window.location.pathname
        );
        if (found) {
          setMenuData([lmenu[index]]);
        }
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ul className="nk-menu">
      {data.map((item, index) => {
        if (item.heading) {
          return <MenuHeading heading={item.heading} key={item.heading} />;
        } else if (item.panel) {
          return (
            <PanelItem
              key={item.text}
              link={item.link}
              icon={item.icon}
              text={item.text}
              index={index}
              panel={item.panel}
              subPanel={item.subPanel}
              data={data}
              setMenuData={setMenuData}
              sidebarToggle={sidebarToggle}
            />
          );
        }

        return (
          <MenuItem
            key={item.text}
            link={item.link}
            icon={item.icon}
            text={item.text}
            sub={item.subMenu}
            badge={item.badge}
            panel={item.panel}
            subPanel={item.subPanel}
            sidebarToggle={sidebarToggle}
          />
        );
      })}
    </ul>
  );
};

export default Menu;
