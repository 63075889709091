import Logo from "../logo/Logo";
import HeaderSearch from "../header-search/HeaderSearch";
import User from "./dropdown/user/User";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import ChatDropdown from "./dropdown/chat/Chat";
import Notification from "./dropdown/notification/Notification";
import { appPortalProductionHostname, appPortalStagingHostname } from "../../constants";

const Header = ({ fixed, theme, className, setVisibility, ...props }) => {
  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme === "white",
    [`is-${theme}`]: theme !== "white" && theme !== "light",
    [`${className}`]: className,
  });
  const hostname = window.location.hostname;
  var showOptions = true;
  if (
    hostname === appPortalProductionHostname ||
    hostname === appPortalStagingHostname
  ) {
    showOptions = false;
  }

  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <Toggle
              className="nk-nav-toggle nk-quick-nav-icon d-xl-none ml-n1"
              icon="menu"
              click={props.sidebarToggle}
            />
          </div>
          <div className="nk-header-brand d-xl-none">
            <Logo />
          </div>
          {/* <div className="nk-header-search ml-3 ml-xl-0">
            <HeaderSearch />
          </div> */}
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              {/* {showOptions && (
                <li
                  className="chats-dropdown hide-mb-xs"
                  onClick={() => setVisibility(false)}
                >
                  <ChatDropdown />
                </li>
              )}
              {showOptions && (
                <li
                  className="notification-dropdown mr-n1"
                  onClick={() => setVisibility(false)}
                >
                  <Notification />
                </li>
              )} */}
              <li className="user-dropdown">
                <User />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
