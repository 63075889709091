import { useDispatch, useSelector } from "react-redux";
import { useLayoutEffect, useState, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Button, Spinner } from "reactstrap";
import {
  employmentDetailsColumns,
  employmentsColumns,
  workingDaysOptions,
} from "./DataColumns";
import { toast } from "react-toastify";
import moment from "moment";
import {
  toggleModal,
  onChangePageState,
  onChangeSearchText,
  onFormCancel,
} from "../../../redux/features/app-portal/employment/employmentDetailsSlice";
import { WorkingHoursField } from "../../../components/form/WorkingHoursField";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
  DatePickerInput,
  Icon,
  ModalComponent,
  PreviewCard,
  ReactDataTable,
  Row,
  SelectField,
  TextInput,
  TextareaInput,
} from "../../../components/Components";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  useCreateEmploymentMutation,
  useDeleteEmploymentMutation,
  useGetEmploymentDetailsQuery,
  useUpdateEmploymentMutation,
} from "../../../redux/api/employment-management/employmentApi";
import {
  useGetOrganisationQuery,
  useLazyGetOrganisationQuery,
} from "../../../redux/api/organisations/organisationApi";
import {
  contractTypeOptions,
  salaryUnitOptions,
  workTypeOptions,
} from "../../../constants";
import { AsyncSelectField } from "../../../components/form/SelectField";
import { useLazyGetAppUsersQuery } from "../../../redux/api/users/usersApi";

const EmploymentDetails = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const state = useSelector((state) => state.employmentDetails);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    resetField,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      contract_type: contractTypeOptions[0],
      work_type: workTypeOptions[0],
      salary_unit: salaryUnitOptions[0],
      working_days: [],
      working_hour_start: "",
      working_hour_end: "",
      lunch_time_start: "",
      lunch_time_end: "",
      workplace: "",
      same_everyday: true,
      email: "",
    },
  });
  const { data, isFetching, isSuccess } = useGetEmploymentDetailsQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
    search_text: state.searchText,
  });
  const [
    getAppUsers,
    {
      data: appUsers,
      isFetching: isGetAppUsersFetching,
      isSuccess: isGetAppUsersSuccess,
    },
  ] = useLazyGetAppUsersQuery();
  const [tableData, setTableData] = useState(data?.employments ?? []);
  const [totalItems, setTotalItems] = useState(data?.total ?? 0);
  const [
    getOrganisation,
    {
      data: orgData,
      isSuccess: isOrgSuccess,
      isFetching: isOrgFetching,
      isError: isOrgError,
    },
  ] = useLazyGetOrganisationQuery({
    offset: 0,
    limit: 0,
  });
  const [
    createEmployment,
    {
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreateEmploymentMutation();
  const [
    updateEmployment,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateEmploymentMutation();
  const [
    deleteEmployment,
    {
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
    },
  ] = useDeleteEmploymentMutation();
  const [workplaceOptions, setWorkplaceOptions] = useState([]);
  const [defaultUserOptions, setDefaultUserOptions] = useState([]);

  useEffect(() => {
    getAppUsers({ offset: 0, limit: 0, is_pure: true }, true);
  }, []);

  useEffect(() => {
    if (isGetAppUsersSuccess && defaultUserOptions.length === 0) {
      setDefaultUserOptions(
        appUsers?.users?.map((item) => {
          let label = "";
          if (item.user.first_name) {
            label += item.user.first_name;
          }
          if (item.user.last_name) {
            label += " " + item.user.last_name;
          }
          label += " - " + item.user.email;
          return { label: label, value: item.user.email };
        })
      );
    }
  }, [isGetAppUsersSuccess]);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useEffect(() => {
    if (user) {
      getOrganisation({
        offset: 0,
        limit: 0,
        id: user?.organisation_id,
      });
    }
  }, [user]);

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.employments);
      setTotalItems(data.total);
    }
  }, [data]);

  const processData = (rawData) => {
    var data = { ...rawData };

    data.contract_type = data.contract_type.value;
    data.work_type = data.work_type.value;
    data.workplace_id = data.workplace.value;
    data.organisation_id = user.organisation_id;
    data.salary_unit = data.salary_unit.value;
    data.scope_list = [
      {
        name: data.job_scope,
        task: [data.job_tasks],
      },
    ];
    data.start_date = data.start_date
      ? new Date(data.start_date).toISOString()
      : null;
    data.end_date = data.end_date
      ? new Date(data.end_date).toISOString()
      : null;

    if (data.same_everyday) {
      for (let i = 0; i < data.working_days.length; i++) {
        data.working_days[i].working_time_from =
          new Date(data.working_hour_start)?.getHours() * 60 +
          new Date(data.working_hour_start)?.getMinutes();
        data.working_days[i].working_time_to =
          new Date(data.working_hour_end)?.getHours() * 60 +
          new Date(data.working_hour_end)?.getMinutes();

        if (data.lunch_time_start) {
          data.working_days[i].lunch_time_from =
            new Date(data.lunch_time_start)?.getHours() * 60 +
            new Date(data.lunch_time_start)?.getMinutes();
        }
        if (data.lunch_time_end) {
          data.working_days[i].lunch_time_to =
            new Date(data.lunch_time_end)?.getHours() * 60 +
            new Date(data.lunch_time_end)?.getMinutes();
        }
      }
    } else {
      var workingDays = [];
      for (let i = 0; i < data.working_days.length; i++) {
        if (data.working_days[i]) {
          if (
            data.working_days[i].working_hour_start &&
            data.working_days[i].working_hour_end
          ) {
            workingDays.push({
              day_of_week: i + 1 === 7 ? 0 : i + 1,
              working_time_from:
                new Date(data.working_days[i].working_hour_start)?.getHours() *
                  60 +
                new Date(data.working_days[i].working_hour_start)?.getMinutes(),
              working_time_to:
                new Date(data.working_days[i].working_hour_end)?.getHours() *
                  60 +
                new Date(data.working_days[i].working_hour_end)?.getMinutes(),
              lunch_time_from:
                new Date(data.working_days[i].lunch_time_start)?.getHours() *
                  60 +
                new Date(data.working_days[i].lunch_time_start)?.getMinutes(),
              lunch_time_to:
                new Date(data.working_days[i].lunch_time_end)?.getHours() * 60 +
                new Date(data.working_days[i].lunch_time_end)?.getMinutes(),
            });
          }
        }
      }
      data.working_days = workingDays;
    }

    return data;
  };

  const onFormSubmit = (data) => {
    var newData = processData(data);
    if (state.modal.add) {
      createEmployment(newData);
    } else if (state.modal.edit) {
      updateEmployment({
        params: { id: state.modal.item.id },
        data: newData,
      });
    }
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(toggleModal({ type: "add" }));
      reset();
    }
    if (isCreateError && createError) {
      toast.error(createError?.data?.message);
    }
  }, [isCreateSuccess, isCreateError]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(toggleModal({ type: "edit" }));
      reset();
    }
    if (isUpdateError && updateError) {
      toast.error(updateError?.data?.message);
    }
  }, [isUpdateError, isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteError && deleteError) {
      toast.error(deleteError?.data?.message);
    }
  }, [isDeleteError]);

  useEffect(() => {
    if (isOrgSuccess && orgData) {
      var options = orgData.addresses?.map((item, index) => {
        return { label: item.name + " - " + item.address, value: item.id };
      });
      setWorkplaceOptions(options);
      setValue("workplace", options[0]);
    }
  }, [isOrgSuccess]);

  const onAddBtnClicked = (e) => {
    dispatch(toggleModal({ type: "add" }));
    reset();
  };

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    // eslint-disable-next-line default-case
    switch (action) {
      case "remove":
        deleteEmployment({
          ids: [row.id],
        });
        break;
      case "details":
        dispatch(toggleModal({ type: "details", item: row }));
        break;
      case "edit":
        dispatch(toggleModal({ type: "edit", item: row }));
        let workingTimeFromHour = Math.floor(row.working_time_from / 60);
        let workingTimeFromMinute = row.working_time_from % 60;
        let workingTimeToHour = Math.floor(row.working_time_to / 60);
        let workingTimeToMinute = row.working_time_to % 60;
        let lunchTimeFromHour = Math.floor(row.lunch_time_from / 60);
        let lunchTimeFromMinute = row.lunch_time_from % 60;
        let lunchTimeToHour = Math.floor(row.lunch_time_to / 60);
        let lunchTimeToMinute = row.lunch_time_to % 60;

        reset(
          {
            email: row.email,
            job_scope: row.scope_list[0]?.name,
            job_tasks: row.scope_list[0]?.task[0],
            same_everyday: row.same_everyday,
            working_hour_start: new Date().setHours(
              workingTimeFromHour,
              workingTimeFromMinute
            ),
            working_hour_end: new Date().setHours(
              workingTimeToHour,
              workingTimeToMinute
            ),
            lunch_time_start: new Date().setHours(
              lunchTimeFromHour,
              lunchTimeFromMinute
            ),
            lunch_time_end: new Date().setHours(
              lunchTimeToHour,
              lunchTimeToMinute
            ),
            start_date: moment(row.start_date).toDate(),
            end_date: moment(row.end_date).toDate(),
            contract_type: {
              value: row?.contract_type,
              label: contractTypeOptions.find(
                (option) => option.value === row?.contract_type
              )?.label,
            },
            work_type: {
              value: row?.work_type,
              label: workTypeOptions.find(
                (option) => option.value === row?.work_type
              )?.label,
            },
            salary: row?.salary,
            salary_unit: {
              value: row?.salary_unit,
              label: salaryUnitOptions.find(
                (option) => option.value === row?.salary_unit
              )?.label,
            },
            workplace: {
              value: row?.workplace_id,
              label: row?.workplace_name,
            },
            // organisation: {
            //   value: row?.organisation_id,
            //   label: row?.organisation_name,
            // },
          },
          {
            keepDefaultValues: true,
          }
        );

        break;
    }
  };

  const loadUsersOptions = async (inputValue, callback) => {
    if (inputValue.length < 3) return;
    let response = await getAppUsers(
      { offset: 0, limit: 0, search_text: inputValue, is_pure: true },
      true
    ).unwrap();

    if (response.users) {
      const options = response.users.map((item) => {
        let label = "";
        if (item.user.first_name) {
          label += item.user.first_name;
        }
        if (item.user.last_name) {
          label += " " + item.user.last_name;
        }
        label += " - " + item.user.email;

        return { label: label, value: item.user.email };
      });
      callback(options);
    }
  };

  const noOptionsMessage = (data) => {
    if (data.inputValue.length >= 3) {
      return "No results found";
    }
    return "Minimum 3 characters required";
  };

  return (
    <>
      <Head title="Employment Details" />
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Employment Details</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle btn-icon d-md-none"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                  </Button>
                  <Button
                    className="toggle d-none d-md-inline-flex"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                    <span>Add Employment</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <div className="table-wrapper is-scrollable">
              <ReactDataTable
                data={tableData}
                columns={employmentDetailsColumns}
                offset={state.offset}
                itemsPerPage={state.itemsPerPage}
                pagination
                totalItems={totalItems}
                progressPending={isFetching}
                className="nk-tb-list"
                selectableRows
                changePageState={changePageState}
                onRowClicked={onRowClicked}
                placeholderSearchText={"Search by email"}
                searchFunc={(searchText) => {
                  dispatch(onChangeSearchText(searchText));
                }}
              />
            </div>
          </PreviewCard>
        </Block>

        {/* <ModalComponent
          isOpen={state.modal.details}
          toggle={() => dispatch(toggleModal({ type: "details" }))}
          title={"Employment Details"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {state.modal.item && (
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text">Employment Id</span>
                <span className="caption-text">{state.modal.item.id}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Email</span>
                <span className="caption-text">{state.modal.item.email}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Organisation</span>
                <span className="caption-text">
                  {state.modal.item.organisation_name}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Workplace</span>
                <span className="caption-text">
                  {state.modal.item.workplace_name}
                </span>
              </Col>
              <Col lg={12}>
                <span className="sub-text">Job Scope</span>
                <span className="caption-text">
                  {state.modal.item.scope_list
                    ? state.modal.item.scope_list[0]?.name
                    : ""}
                </span>
              </Col>
              <Col lg={12}>
                <span className="sub-text">Job Tasks</span>
                {state.modal.item.scope_list &&
                  state.modal.item.scope_list[0]?.task.map((item, index) => {
                    return (
                      <span
                        className="caption-text"
                        key={`${state.modal.item.id}-job-tasks-${index}`}
                      >
                        {item}
                        <br />
                      </span>
                    );
                  })}
              </Col>
              <Col lg={12}>
                <span className="sub-text">Working Days</span>
                <span className="caption-text">
                  {state.modal.item.working_days?.map((item, index) => {
                    let f = workingDaysOptions.find(
                      (option) => parseInt(option.value) === item.day_of_week
                    );

                    let text = f?.label;
                    if (
                      item.working_time_from_text &&
                      item.working_time_to_text
                    ) {
                      text += `: ${item.working_time_from_text} - ${item.working_time_to_text}`;
                    }
                    if (item.lunch_time_from_text && item.lunch_time_to_text) {
                      text += ` (Lunch: ${item.lunch_time_from_text} - ${item.lunch_time_to_text})`;
                    }

                    return (
                      <span
                        className="caption-text"
                        key={`${state.modal.item.id}-working-days-${index}`}
                      >
                        {text}
                        <br />
                      </span>
                    );
                  })}
                </span>
              </Col>

              <Col lg={6}>
                <span className="sub-text">Created Time</span>
                <span className="caption-text">
                  {moment(state.modal.item.created_time).format(
                    "DD-MM-YYYY, hh:mm:ss a"
                  )}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Last Updated Time</span>
                <span className="caption-text">
                  {moment(state.modal.item.last_updated_time).format(
                    "DD-MM-YYYY, hh:mm:ss a"
                  )}
                </span>
              </Col>
            </Row>
          )}
        </ModalComponent> */}

        <ModalComponent
          isOpen={state.modal.add || state.modal.edit}
          title={`${state.modal.add ? "Add" : "Edit"} Employment`}
          onModalCancel={() => {
            dispatch(onFormCancel());
            reset();
          }}
          toggle={() => {
            dispatch(onFormCancel());
            reset();
          }}
          backdrop={"static"}
        >
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Row className="g-3">
              <Col size="12">
                <label className="form-label">User</label>
                <Col size="0">
                  <AsyncSelectField
                    defaultValue={
                      state.modal.add
                        ? null
                        : {
                            label: `${state.modal.item?.first_name} ${state.modal.item?.last_name} - ${state.modal.item?.email}`,
                            value: state.modal.item?.email,
                          }
                    }
                    defaultOptions={defaultUserOptions}
                    isLoading={isGetAppUsersFetching}
                    isDisabled={isGetAppUsersFetching}
                    onChangeFunc={(newValue) => {
                      // onAsyncSelectChanged(newValue, metricsSoftSkillsType);
                      setValue("email", newValue.value);
                    }}
                    loadOptions={async (inputValue, callback) => {
                      await loadUsersOptions(inputValue, callback);
                    }}
                    noOptionsMessage={(data) => noOptionsMessage(data)}
                  />
                </Col>
              </Col>
              {/* <TextInput
                name={"email"}
                label={"Email"}
                required
                register={{
                  ...register("email", {
                    required: "This field is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  }),
                }}
                errors={errors}
                md="12"
              /> */}
              <SelectField
                name={"contract_type"}
                label={"Contract Type"}
                control={control}
                rules={{ required: "This field is required" }}
                defaultValue={contractTypeOptions[0]}
                options={contractTypeOptions}
                errors={errors}
              />
              <SelectField
                name={"work_type"}
                label={"Work Type"}
                control={control}
                rules={{ required: "This field is required" }}
                defaultValue={workTypeOptions[0]}
                options={workTypeOptions}
                errors={errors}
              />
              <SelectField
                name={"workplace"}
                label={"Workplace"}
                isSearchable={false}
                control={control}
                rules={{ required: "This field is required" }}
                options={workplaceOptions}
                isDisabled={isOrgFetching}
                errors={errors}
                md={12}
              />
              {/* <TimePickerGroupInput
                nameStart={"working_hour_start"}
                nameEnd={"working_hour_end"}
                label={"Working hours"}
                control={control}
                errors={errors}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Times"
                dateFormat="h:mm aa"
              />
              <TimePickerGroupInput
                nameStart={"lunch_time_start"}
                nameEnd={"lunch_time_end"}
                label={"Lunch Time"}
                control={control}
                errors={errors}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Times"
                dateFormat="h:mm aa"
              />
              <SelectField
                name={"working_days"}
                label={"Working Days"}
                control={control}
                // rules={{ required: "This field is required" }}
                options={workingDaysOptions}
                isMulti
                closeMenuOnSelect={false}
                errors={errors}
              /> */}
              {/* <TextInput
                name={"job_scope"}
                label={"Job Scope"}
                required
                register={{
                  ...register("job_scope", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
                md={12}
              />
              <TextareaInput
                name={"job_tasks"}
                label={"Job Tasks"}
                required
                register={{
                  ...register("job_tasks", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
                md={12}
              /> */}
              <DatePickerInput
                name={"start_date"}
                label={"Start date"}
                placeholder={"dd/mm/yyyy"}
                dateFormat={"dd/MM/yyyy"}
                control={control}
                errors={errors}
              />
              <DatePickerInput
                name={"end_date"}
                label={"End date"}
                placeholder={"dd/mm/yyyy"}
                dateFormat={"dd/MM/yyyy"}
                control={control}
                errors={errors}
              />
              <WorkingHoursField
                name={"working_days"}
                label={"Working Hours"}
                setValue={setValue}
                getValues={getValues}
                required
                control={control}
                errors={errors}
                resetField={resetField}
                item={state.modal.edit ? state.modal.item : null}
                md={12}
              />
              <TextInput
                name={"salary"}
                label={"Salary"}
                register={{
                  ...register("salary"),
                }}
                errors={errors}
              />
              <SelectField
                name={"salary_unit"}
                label={"Salary Unit"}
                control={control}
                options={salaryUnitOptions}
                defaultValue={salaryUnitOptions[0]}
                errors={errors}
              />

              <Col size="12">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isCreateLoading || isUpdateLoading}
                >
                  {(isCreateLoading || isUpdateLoading) && (
                    <Spinner type="grow" size="sm" />
                  )}
                  <span>{state.modal.add ? "Add" : "Edit"}</span>
                </Button>
              </Col>
            </Row>
          </form>
        </ModalComponent>
      </Content>
    </>
  );
};
export default EmploymentDetails;
