import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Row,
  Col,
  ModalComponent,
  TextInput,
  ReactDataTable,
  OverlineTitle,
  TextareaInput,
  PreviewAltCard,
} from "../../../components/Components";
import { Badge, Card } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useGetStoriesQuery } from "../../../redux/api/chatbot/chatbotApi";

const Conversations = () => {
  const navigate = useNavigate();
  const {
    data: storiesData,
    isFetching: isGetStoriesFetching,
    isSuccess: isGetStoriesSuccess,
    isError: isGetStoriesError,
    error: getStoriesError,
  } = useGetStoriesQuery();

  return (
    <>
      <Head title="Conversations"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle page>Conversations</BlockTitle>
            </BlockHeadContent>
          </BlockHead>
        </Block>

        <Block>
          <BlockHead>
            <BlockTitle tag="h5">Stories</BlockTitle>
          </BlockHead>
          {/* <Row className="g-gs">
            {test.map((item) => (
              <Col md={3} xxl={3} key={item.id}>
                <Card className={`pricing ${item.tags ? "recommend" : ""}`}>
                  {item.tags && (
                    <Badge color="primary" className="pricing-badge">
                      Recommend
                    </Badge>
                  )}
                  <div className="pricing-head">
                    <div className="pricing-title">
                      <h5 className="card-title title">{item.title}</h5>
                      <p className="sub-text">{item.caption}</p>
                    </div>

                  </div>
                  <div className="pricing-body">
                    <ul className="pricing-features">
                      <li>
                        <span className="w-50">Interactions</span> -{" "}
                        <span
                          className="ms-auto"
                          style={{ marginLeft: "auto" }}
                        >
                          ${item.minDeposit}
                        </span>
                      </li>
                    </ul>
                    <div className="pricing-action">
                      <Button color="primary">Edit</Button>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
            <Col md={2} xxl={2}>
              <Card className="pricing" style={{ minHeight: "200px" }}>
                <Button color="primary">Add New</Button>
              </Card>
            </Col>
          </Row> */}
          <Row>
            {storiesData?.map((story) => (
              <div key={`${story.id}`} className="mx-2">
                <PreviewAltCard>
                  <Badge
                    color="danger"
                    className="pricing-badge"
                    style={{ padding: "3px 3px", cursor: "pointer" }}
                  >
                    <Icon name="trash" />
                  </Badge>
                  <div className="pricing-head">
                    <div className="pricing-title">
                      <h5 className="card-title title">{story.name}</h5>
                      <p className="sub-text">{story.type.toUpperCase()}</p>
                    </div>
                  </div>
                  <div className="p-1">
                    <ul className="pricing-features">
                      <li>
                        <span>{`Interactions: ${
                          story.steps?.length ?? 0
                        }`}</span>
                      </li>
                    </ul>
                    <div className="pricing-action">
                      <Button
                        color="primary"
                        onClick={() =>
                          navigate(`./story/${story.id}`)
                        }
                      >
                        Edit
                      </Button>
                    </div>
                  </div>
                </PreviewAltCard>
              </div>
            ))}
            <div className="d-flex mx-2 w-min-175px">
              <PreviewAltCard
                className="justify-content-center"
                style={{ height: "100%", width: "100%" }}
              >
                <div className="d-flex flex-column">
                  <Button
                    color="primary"
                    className="d-inline-block mb-2 mx-3"
                    onClick={(ev) => {
                      navigate("./story/new_custom");
                    }}
                  >
                    <Icon name="plus"></Icon>
                  </Button>
                  <p
                    className="card-title title align-self-center"
                    style={{ fontSize: ".875rem" }}
                  >
                    Create new story
                  </p>
                </div>
              </PreviewAltCard>
            </div>
          </Row>
        </Block>
      </Content>
    </>
  );
};

const test = [];

const testData = [
  {
    id: 1,
    title: "Basic",
    caption: "For a small team",
    interest: 5,
    return: 30,
    minDeposit: 100,
    maxDeposit: 1000,
    totalReturn: 150,
  },
  {
    id: 2,
    title: "Standard",
    caption: "For a medium team",
    interest: 10,
    return: 30,
    minDeposit: 1000,
    maxDeposit: 10000,
    totalReturn: 300,
    tags: ["recommend"],
  },
  {
    id: 3,
    title: "Premium",
    caption: "For a large team",
    interest: 15,
    return: 30,
    minDeposit: 10000,
    maxDeposit: 100000,
    totalReturn: 450,
  },
];

export default Conversations;
