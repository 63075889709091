import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  offset: 0,
  itemsPerPage: 10,
  modal: {
    add: false,
    details: false,
    item: null,
  },
};

export const checkInOutLogSlice = createSlice({
  name: "checkInOutLog",
  initialState: initialState,
  reducers: {
    onChangePageState(state, action) {
      const { offset, itemsPerPage } = action.payload;
      return {
        ...state,
        offset,
        itemsPerPage,
      };
    },
    onFormCancel(state, action) {
      return {
        ...state,
        modal: initialState.modal,
      };
    },
    onChangeSearchText(state, action) {
      state.searchText = action.payload;
    },
    onChangeClearSelectedRows(state, action) {
      const clearSelectedRows = action.payload;
      if (clearSelectedRows === true) {
        return {
          ...state,
          clearSelectedRows: clearSelectedRows,
          selectedRows: null,
        };
      }
      return { ...state, clearSelectedRows: clearSelectedRows };
    },
    onSelectedRowsChange(state, action) {
      const selectedRows = action.payload;
      return { ...state, selectedRows: selectedRows };
    },
    toggleModal(state, action) {
      // const { type } = action.payload;
      // return {
      //   ...state,
      //   modal: {
      //     ...state.modal,
      //     [type]: !state.modal[type],
      //   },
      // };
      const { type, item } = action.payload;
      state.modal[type] = !state.modal[type];
      state.modal.item = item;
    },
  },
});

export const {
  onChangePageState,
  onFormCancel,
  toggleModal,
  onChangeSearchText,
  onChangeClearSelectedRows,
  onSelectedRowsChange,
} = checkInOutLogSlice.actions;

export default checkInOutLogSlice.reducer;
