import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import ReactFlow, {
  Controls,
  Background,
  MiniMap,
  Handle,
  Position,
  ReactFlowProvider,
  useReactFlow,
  useNodesState,
  useEdgesState,
  addEdge,
} from "reactflow";
import "reactflow/dist/style.css";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Components";
import Head from "../../../layout/head/Head";
import { Breadcrumb, BreadcrumbItem, Button, Spinner } from "reactstrap";
import { TextUpdaterNode, TestNode, ComponentNode } from "./CustomNodes";
import {
  useCreateStoryMutation,
  useGetIntentsQuery,
  useGetStoriesQuery,
  useLazyGetIntentExamplesQuery,
  useLazyGetStoriesQuery,
  useLazyGetIntentsQuery,
  useLazyGetUtterancesQuery,
} from "../../../redux/api/chatbot/chatbotApi";
import { toast } from "react-toastify";
import { set } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { Sidebar } from "./Sidebar";
import { components } from "react-select";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const templateTypes = ["q&a", "custom", "custom_rule", "multiflow"];

const getTemplateTypeFromId = (id) => {
  // Extract the template type part from the id
  const templateType = id.slice(4); // Remove "new_"

  // Check if the extracted part is in the templateTypes array
  return templateTypes.includes(templateType) ? templateType : null;
};

const ConversationsBuilder = () => {
  const { id } = useParams();
  const [templateType, setTemplateType] = useState("");
  const [type, setType] = useState("");
  const [
    getStory,
    {
      data: storyData,
      isUninitialized: isGetStoriesUninitialized,
      isFetching: isGetStoriesFetching,
      isSuccess: isGetStoriesSuccess,
      isError: isGetStoriesError,
      error: getStoriesError,
    },
  ] = useLazyGetStoriesQuery();

  useEffect(() => {
    let templateType = getTemplateTypeFromId(id);
    if (templateType) {
      setTemplateType(templateType);
    } else {
      getStory({ id: id });
    }
  }, []);

  useEffect(() => {
    if (isGetStoriesError && getStoriesError) {
      toast.error(getStoriesError.data?.message);
    }
    if (isGetStoriesSuccess) {
      console.log(storyData);
      setTemplateType(storyData.template_type);
    }
  }, [isGetStoriesSuccess, isGetStoriesError]);

  useEffect(() => {
    if (templateType) {
      switch (templateType) {
        case "q&a":
          setType("rule");
          break;
        case "custom":
          setType("story");
          break;
        case "custom_rule":
          setType("rule");
          break;
        case "multiflow":
          setType("multiflow");
          break;
        default:
          setType("");
      }
    }
  }, [templateType]);

  if (isGetStoriesFetching || !templateType || !type) {
    return <Spinner />;
  }

  return (
    <ReactFlowProvider>
      <FlowBuilder story={storyData} templateType={templateType} type={type} />
    </ReactFlowProvider>
  );
};

const edgeOptions = {
  // animated: true,
  // style: {
  //   stroke: "black",
  // },
};

const FlowBuilder = ({ story, templateType, type }) => {
  const reactFlowInstance = useReactFlow();
  const navigate = useNavigate();
  // const {
  //   state: { story, templateType, type },
  // } = useLocation();
  const [selectedComponentTab, setSelectedComponentsTab] = useState("intents");
  const [selectedComponentNode, setSelectedComponentNode] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const observedDiv = useRef(null);
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const nodesRef = useRef(null);
  const edgesRef = useRef(null);
  const currentComponentTabRef = useRef(null);
  const [storyName, setStoryName] = useState("Untitled");
  const [needToSave, setNeedToSave] = useState(false);

  const [
    getItents,
    {
      data: intentsData,
      isFetching: isGetIntentsFetching,
      isSuccess: isGetIntentsSuccess,
      isError: isGetIntentsError,
      error: getIntentsError,
    },
  ] = useLazyGetIntentsQuery();

  const [
    getUtterances,
    {
      data: utterancesData,
      isFetching: isGetUtterancesFetching,
      isSuccess: isGetUtterancesSuccess,
      isError: isGetUtterancesError,
      error: getUtterancesError,
    },
  ] = useLazyGetUtterancesQuery();
  const [
    createStory,
    {
      isLoading: isCreateStoryLoading,
      isError: isCreateStoryError,
      isSuccess: isCreateStorySuccess,
      error: createStoryError,
    },
  ] = useCreateStoryMutation();

  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  useEffect(() => {
    if (nodes) {
      nodesRef.current = nodes;
    }
    if (edges) {
      edgesRef.current = edges;
    }
  }, [nodes, edges]);

  useEffect(() => {
    var newNodes = [
      {
        id: "components-tab-node",
        type: "testNode",
        position: { x: 100, y: 100 },
        data: {
          intents: [],
          utterances: [],
          componentsTab: selectedComponentTab,
          setComponentsTab: setSelectedComponentsTab,
          addToStory: onAddComponentNodeToStory,
        },
        zIndex: 9999,
      },
    ];

    if (story) {
      setStoryName(story.name);

      story.steps.forEach((step, index) => {
        let nodeId = `component-${step.type}-node-${index}-${uuidv4()}`;
        newNodes.push({
          id: nodeId,
          type: "componentNode",
          connectable: true,
          position: {
            x:
              newNodes.length > 1
                ? newNodes[newNodes.length - 1].position.x
                : newNodes[newNodes.length - 1].position.x + 300,
            y:
              newNodes.length > 1
                ? newNodes[newNodes.length - 1].position.y + 100
                : newNodes[newNodes.length - 1].position.y,
          },
          data: {
            nodeId: nodeId,
            type: step.type,
            item: step,
            onNodeClicked: setSelectedComponentNode,
            onNodeDelete: onComponentNodeDelete,
          },
        });
      });
    }

    setNodes((nodes) => [...nodes, ...newNodes]);
  }, []);

  useEffect(() => {
    if (selectedComponentTab) {
      currentComponentTabRef.current = selectedComponentTab;

      if (selectedComponentTab === "intents") {
        getItents();
      }
      if (selectedComponentTab === "utterances") {
        getUtterances();
      }
    }
  }, [selectedComponentTab]);

  const onComponentNodeDelete = (nodeId) => {
    console.log(nodeId);
    setNodes((nodes) => nodes.filter((node) => node.id !== nodeId));
  };

  const onAddComponentNodeToStory = ({ type, data }) => {
    if (templateType === "custom") {
      if (nodesRef.current.length === 1) {
        if (currentComponentTabRef.current !== "intents") {
          toast.error("First step must be an intent!");
          return;
        }
      }

      if (nodesRef.current.length >= 2) {
        if (
          nodesRef.current[nodesRef.current.length - 1].data.type ===
            "intents" &&
          currentComponentTabRef.current === "intents"
        ) {
          toast.error("Intent should not be followed by an another intent");
          return;
        }
      }
    }

    setNodes((nodes) => {
      let nodeId = `component-${type}-node-${data.id}-${uuidv4()}`;
      return [
        ...nodes,
        {
          id: nodeId,
          type: "componentNode",
          connectable: true,
          position: {
            x:
              nodes.length > 1
                ? nodes[nodes.length - 1].position.x
                : nodes[nodes.length - 1].position.x + 300,
            y:
              nodes.length > 1
                ? nodes[nodes.length - 1].position.y + 100
                : nodes[nodes.length - 1].position.y,
          },
          data: {
            nodeId: nodeId,
            type: type,
            item: data,
            onNodeClicked: setSelectedComponentNode,
            onNodeDelete: onComponentNodeDelete,
          },
        },
      ];
    });
  };

  useEffect(() => {
    var componentsNode = nodes.filter((node) => node.type === "componentNode");

    if (
      componentsNode.length >= 2 &&
      componentsNode.length - 1 !== edgesRef.current.length
    ) {
      let newEdges = [];
      for (let i = componentsNode.length - 1; i > 0; i--) {
        newEdges.push({
          id: `edge-${uuidv4()}`,
          source: componentsNode[i - 1].id,
          target: componentsNode[i].id,
        });
      }
      setEdges((edges) => [...newEdges]);
    }

    // if (
    //   componentsNode.length >= 2 &&
    //   componentsNode.length - 1 !== edgesRef.current.length
    // ) {
    //   setEdges((edges) => [
    //     ...edges,
    //     {
    //       id: `edge-${uuidv4()}`,
    //       source: nodes[nodes.length - 2].id,
    //       target: nodes[nodes.length - 1].id,
    //     },
    //   ]);
    // }
  }, [nodes, setEdges]);

  useEffect(() => {
    if (selectedComponentNode) {
      setSidebarVisible(true);
    }
  }, [selectedComponentNode]);

  useEffect(() => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === "components-tab-node") {
          node.data = {
            ...node.data,
            componentsTab: selectedComponentTab,
          };
        }

        return node;
      })
    );
  }, [selectedComponentTab, setNodes]);

  useEffect(() => {
    if (isGetUtterancesSuccess) {
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === "components-tab-node") {
            node.data = {
              ...node.data,
              utterances: utterancesData,
            };
          }

          return node;
        })
      );
    }
    if (isGetUtterancesError && getUtterancesError) {
      toast.error(getUtterancesError.data?.message);
    }
  }, [isGetUtterancesSuccess, isGetUtterancesError]);

  useEffect(() => {
    if (isGetIntentsSuccess) {
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === "components-tab-node") {
            node.data = {
              ...node.data,
              intents: intentsData,
            };
          }

          return node;
        })
      );
    }
    if (isGetIntentsError && getIntentsError) {
      toast.error(getIntentsError.data?.message);
    }
  }, [isGetIntentsSuccess, isGetIntentsError]);

  useEffect(
    () => {
      if (!observedDiv.current) {
        // we do not initialize the observer unless the ref has
        // been assigned
        return;
      }

      // we also instantiate the resizeObserver and we pass
      // the event handler to the constructor
      const resizeObserver = new ResizeObserver(() => {
        if (observedDiv.current.offsetWidth !== width) {
          setWidth(observedDiv.current.offsetWidth);
        }
        if (observedDiv.current.offsetHeight !== height) {
          setHeight(observedDiv.current.offsetHeight);
        }
      });

      // the code in useEffect will be executed when the component
      // has mounted, so we are certain observedDiv.current will contain
      // the div we want to observe
      resizeObserver.observe(observedDiv.current);

      // if useEffect returns a function, it is called right before the
      // component unmounts, so it is the right place to stop observing
      // the div
      return function cleanup() {
        resizeObserver.disconnect();
      };
    },
    // only update the effect if the ref element changed
    [height, width]
  );

  const nodeTypes = useMemo(
    () => ({
      textUpdater: TextUpdaterNode,
      testNode: TestNode,
      componentNode: ComponentNode,
    }),
    []
  );

  useEffect(() => {
    if (isCreateStorySuccess) {
      toast.success("Story created successfully");
      navigate("../../", { replace: true });
    }
    if (isCreateStoryError && createStoryError) {
      toast.error(createStoryError.data?.message);
    }
  }, [isCreateStorySuccess, isCreateStoryError]);

  const onSubmitStory = ({ storyName, nodes }) => {
    console.log(story);

    if (storyName === "" || storyName === "Untitled") {
      toast.error("Enter story name");
      return;
    }
    const hasIntentsNode = nodes.some((node) => node.data.type === "intents");
    const hasUtterancesNode = nodes.some(
      (node) => node.data.type === "utterances"
    );
    console.log(hasIntentsNode, hasUtterancesNode);
    if (!hasIntentsNode || !hasUtterancesNode) {
      toast.error("Add at least one intent and one utterance to the story");
      return;
    }

    let data = {
      name: storyName,
      templateType: templateType,
      type: type,
      steps: nodes.map((node) => ({
        name: node.data.item.name,
        type: node.data.type,
      })),
    };

    //TODO: Validate data

    console.log(data);
    createStory(data);
  };


  const onNodeMouseEnter = (event, node) => {
    if (node.type === "componentNode") {
      //TODO: show tooltip
    }
  };

  return (
    <>
      <Head title="Story"></Head>
      <Content>
        <BlockHead size="sm">
          <Breadcrumb
            tag="nav"
            listTag="div"
            className="breadcrumb-arrow fs-18px"
          >
            <BreadcrumbItem tag="a" href="../">
              Conversations
            </BreadcrumbItem>
            <BreadcrumbItem active>{storyName}</BreadcrumbItem>
          </Breadcrumb>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                <input
                  onChange={(e) => setStoryName(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setStoryName("Untitled");
                    }
                  }}
                  value={storyName}
                />
                {/* <strong className="text-primary small">asdasd</strong> */}
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="primary"
                className="d-none d-sm-inline-flex"
                onClick={() =>
                  onSubmitStory({
                    storyName: storyName,
                    nodes: nodes.filter(
                      (node) => node.type === "componentNode"
                    ),
                  })
                }
              >
                {/* <Icon name="arrow-left"></Icon> */}
                <span>Save / Sync</span>
              </Button>
              {/* <a
                href="#back"
                onClick={(ev) => {}}
                className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
              >
                <Icon name="arrow-left"></Icon>
              </a> */}
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        {/* <Block>
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle page>Story</BlockTitle>
            </BlockHeadContent>
          </BlockHead>
        </Block> */}
        <Block ref={observedDiv}>
          <div style={{ height: "75vh", width: width }}>
            <ReactFlow
              // defaultNodes={initialNodes}
              nodes={nodes}
              onNodesChange={onNodesChange}
              edges={edges}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              nodeTypes={nodeTypes}
              defaultEdgeOptions={edgeOptions}
              onNodeMouseEnter={onNodeMouseEnter}
              // fitView
            >
              <Background />
              <Controls />
              <MiniMap />
            </ReactFlow>
          </div>
        </Block>
        <Sidebar
          selectedComponentNode={selectedComponentNode}
          isVisible={isSidebarVisible}
          onCloseSidebarClicked={() => setSidebarVisible(!isSidebarVisible)}
        />
      </Content>
    </>
  );
};

export default ConversationsBuilder;
