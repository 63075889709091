import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect } from "react";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  ReactDataTable,
  DatePickerInput,
  Row,
  Col,
  SelectField,
  TextInput,
  ModalComponent,
} from "../../../components/Components";
import { subcriptionsColumns } from "./DataColumns";
import { onChangePageState, toggleModal, onFormCancel } from "../../../redux/features/app-portal/subcriptions/subcriptionSlice";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useForm } from "react-hook-form";
import { Spinner } from "reactstrap";

const Subcriptions = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.subcription);
  // const { data, isFetching, isSuccess } = useGetGoalFrequenciesQuery({
  //   offset: state.offset,
  //   limit: state.itemsPerPage,
  //   total: true,
  // });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
  } = useForm({ defaultValues: {} });
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  // useLayoutEffect(() => {
  //   if (data) {
  //     setTableData(data.goalFrequencies);
  //     setTotalItems(data.total);
  //   }
  // }, [data]);

  const onAddBtnClicked = (e) => {
    dispatch(toggleModal({ type: "add" }));
  };

  const processData = (data) => {}; 

  // const onFormSubmit = (data) => {
  //   var newData = processData(data);
  //   if (state.modal.add) {
  //     createLog(newData);
  //   } else if (state.modal.edit) {
  //     updateEmotionalLog({
  //       params: { id: state.modal.item.id },
  //       data: newData,
  //     });
  //   }
  // };

  return (
    <>
      <Head title="Subcriptions"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Subcriptions</BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <ReactDataTable
              data={tableData}
              columns={subcriptionsColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              pagination
              totalItems={totalItems}
              // progressPending={isFetching}
              className="nk-tb-list"
              selectableRows
              changePageState={changePageState}
            />
          </PreviewCard>
        </Block>
      </Content>
    
      {/* <ModalComponent
          isOpen={state.modal.add || state.modal.edit}
          title={`${state.modal.add ? "Add" : "Edit"} Log`}
          toggle={() => {
            dispatch(onFormCancel());
            reset();
          }}
          backdrop={"static"}
          onModalCancel={() => {
            dispatch(onFormCancel());
            reset();
          }}
        >
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Row className="g-3">
              <SelectField
                name={"type"}
                label={"Type"}
                control={control}
                rules={{ required: "This field is required" }}
                options={[
                  { value: "starter", label: "Starter" },
                  { value: "essential", label: "Essential" },
                  { value: "pro", label: "Pro" },
                ]}
                defaultValue={{ value: "starter", label: "Starter" }}
                errors={errors}
              />
              <TextInput
                name={"description"}
                label={"Description"}
                required
                register={{
                  ...register("description", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
                md="12"
              />
              <DatePickerInput
              name={"date_effective_from"}
              label={"Date effective from"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"date_effective_to"}
              label={"Date effective to"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />
              

              <Col size="12">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isCreateLoading || isUpdateLoading}
                >
                  {(isCreateLoading || isUpdateLoading) && (
                    <Spinner type="grow" size="sm" />
                  )}
                  <span>{state.modal.add ? "Add" : "Edit"}</span>
                </Button>
              </Col>
            </Row>
          </form>
        </ModalComponent> */}
    </>
  );
};

export default Subcriptions;
