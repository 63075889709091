import { Col, Icon, Row, TooltipComponent } from "../../../components/Components";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";

export const organisationColumns = [
  {
    name: "ID",
    selector: (row) => row.id,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.id}</span>,
    minWidth: "150px",
    hide: "md",
  },
  {
    name: "Name",
    selector: (row) => row.name,
    cell: (row) => <span>{row.name}</span>,
    minWidth: "200px",
    hide: "md",
  },
  {
    name: "Website",
    selector: (row) => row.website,
    cell: (row) => <span>{row.website}</span>,
    minWidth: "200px",
    hide: "md",
  },
  {
    name: "Created",
    selector: (row) => row?.created_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => (
      <span>{moment(row?.created_time).format("DD-MM-YYYY, hh:mm:ss a")}</span>
    ),
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row?.last_updated_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => (
      <span>
        {moment(row?.last_updated_time).format("DD-MM-YYYY, hh:mm:ss a")}
      </span>
    ),
    hide: "md",
  },
  {
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];
