import moment from "moment";

export const subcriptionsColumns = [
  {
    name: "Plan",
    // selector: (row) => row.id,
    compact: true,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.id}</span>,
  },
  {
    name: "Valid From",
    // selector: (row) => row.email,
    minWidth: "120px",
    cell: (row) => <span></span>,
    hide: "md",
  },
  {
    name: "Valid To",
    selector: (row) => row.workplace_name,
    minWidth: "120px",
    cell: (row) => <span>{row.workplace_name}</span>,
    hide: "md",
  },
  {
    name: "Payment Amount",
    selector: (row) => row.workplace_name,
    minWidth: "120px",
    cell: (row) => <span>{row.workplace_name}</span>,
    hide: "md",
  },
  {
    name: "Payment Type",
    selector: (row) => row.workplace_name,
    minWidth: "120px",
    cell: (row) => <span>{row.workplace_name}</span>,
    hide: "md",
  },
  {
    name: "Payment Details",
    selector: (row) => row.workplace_name,
    minWidth: "120px",
    cell: (row) => <span>{row.workplace_name}</span>,
    hide: "md",
  },
  {
    name: "Paid on",
    selector: (row) => row.workplace_name,
    minWidth: "120px",
    cell: (row) => <span>{row.workplace_name}</span>,
    hide: "md",
  },
  {
    name: "In use?",
    selector: (row) => row.workplace_name,
    minWidth: "120px",
    cell: (row) => <span>{row.workplace_name}</span>,
    hide: "md",
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    minWidth: "120px",
    cell: (row) => (
      <span>{moment(row.created_time).format("DD-MM-YYYY, hh:mm:ss a")}</span>
    ),
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    minWidth: "120px",
    cell: (row) => (
      <span>
        {moment(row.last_updated_time).format("DD-MM-YYYY, hh:mm:ss a")}
      </span>
    ),
    hide: "md",
  }
];

export const accountSubcriptionsColumns = [
  {
    name: "User",
    // selector: (row) => row.id,
    compact: true,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.id}</span>,
  },
  {
    name: "Subscription details",
    // selector: (row) => row.email,
    minWidth: "120px",
    cell: (row) => <span></span>,
    hide: "md",
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    minWidth: "120px",
    cell: (row) => (
      <span>{moment(row.created_time).format("DD-MM-YYYY, hh:mm:ss a")}</span>
    ),
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    minWidth: "120px",
    cell: (row) => (
      <span>
        {moment(row.last_updated_time).format("DD-MM-YYYY, hh:mm:ss a")}
      </span>
    ),
    hide: "md",
  }
];