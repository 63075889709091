import { Spinner } from "reactstrap";
import { useUpdateUserGoalsProgressMutation } from "../../../redux/api/goal-management/goalApi";

const GoalTrackingCheckbox = ({ goalProgress, weekday }) => {
  const [
    updateUserGoalProgress,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateUserGoalsProgressMutation();

  if (isUpdateLoading) {
    return <Spinner type="grow" size="sm" />;
  }

  return (
    <div className="custom-control custom-control-sm custom-checkbox notext">
      <input
        id={goalProgress.id + `-completed-day-${weekday}`}
        type="checkbox"
        className="custom-control-input form-control"
        checked={goalProgress.completed_days?.includes(weekday)}
        onChange={(e) => {
          console.log(e.target.checked);

          updateUserGoalProgress({
            params: { id: goalProgress.id },
            data: {
              completed_days: e.target.checked
                ? [...goalProgress.completed_days, weekday]
                : goalProgress.completed_days.filter((day) => day !== weekday),
            },
          });
        }}
      />
      <label
        className="custom-control-label"
        htmlFor={goalProgress.id + `-completed-day-${weekday}`}
      />
    </div>
  );
};

export default GoalTrackingCheckbox;
