import { useEffect, useState } from "react";
import { Col, Icon, PreviewCard, Row } from "../../../components/Components";
import {
  useCreateUtteranceMutation,
  useCreateUtteranceResponsesMutation,
  useGetUtterancesQuery,
  useLazyGetUtteranceResponsesQuery,
} from "../../../redux/api/chatbot/chatbotApi";
import { toast } from "react-toastify";
import { Spinner, UncontrolledTooltip } from "reactstrap";
import TextareaAutosize from "react-textarea-autosize";

export const UtterancesComponents = ({ utterances, setUtterances }) => {
  const {
    data: utterancesData,
    isFetching: isGetUtterancesFetching,
    isSuccess: isGetUtterancesSuccess,
    isError: isGetUtterancesError,
  } = useGetUtterancesQuery({
    is_get_responses: true,
  });
  const [
    getUtteranceResponses,
    {
      data: utteranceResponses,
      isSuccess: isGetUtteranceResponsesSuccess,
      isFetching: isGetUtteranceResponsesFetching,
      isError: isGetUtteranceResponsesError,
    },
  ] = useLazyGetUtteranceResponsesQuery();
  const [
    createUtterance,
    {
      isLoading: isCreateUtteranceLoading,
      isError: isCreateUtteranceError,
      isSuccess: isCreateUtteranceSuccess,
      error: createUtteranceError,
    },
  ] = useCreateUtteranceMutation();
  const [
    createUtteranceResponse,
    {
      isLoading: isCreateUtteranceResponseLoading,
      isError: isCreateUtteranceResponseError,
      isSuccess: isCreateUtteranceResponseSuccess,
      error: createUtteranceResponseError,
    },
  ] = useCreateUtteranceResponsesMutation();
  const [utteranceSearchInput, setUtteranceSearchInput] = useState("");
  const [selectedUtterance, setSelectedUtterance] = useState(null);
  const [utteranceResponseInput, setUtteranceResponseInput] = useState("");
  const [selectedToEditResponse, setSelectedToEditResponse] = useState(null);

  const onUtteranceResponseInputKeydown = (evt) => {
    if (evt.key === "Enter" && evt.shiftKey) {
      if (isCreateUtteranceResponseLoading) return;

      if (utteranceResponseInput) {
        createUtteranceResponse({
          utterance_id: selectedUtterance.id,
          response: utteranceResponseInput,
        });
      }
    }

    if (evt.key === "Escape") {
      if (selectedToEditResponse) {
        setSelectedToEditResponse(null);
      }
    }
  };

  const onUtteranceSearchInputKeydown = (evt) => {
    if (evt.key === "Enter" && evt.shiftKey) {
      if (isCreateUtteranceLoading) return;

      if (utteranceSearchInput) {
        if (
          utterancesData?.find(
            (data) => data.utterance.name === utteranceSearchInput
          )
        ) {
          toast.error("Utterance already exists");
        } else {
          createUtterance({ name: utteranceSearchInput });
        }
      }
    }

    if (evt.key === "Escape") {
      setUtteranceSearchInput("");
    }
  };

  const onSelectUtterance = (utterance) => {
    setSelectedUtterance(utterance);
  };

  useEffect(() => {
    if (selectedToEditResponse) {
      setUtteranceResponseInput(selectedToEditResponse.response);
    } else {
      setUtteranceResponseInput("");
    }
  }, [selectedToEditResponse]);

  useEffect(() => {
    if (isCreateUtteranceSuccess) {
      setUtteranceSearchInput("");
    }
    if (isCreateUtteranceError && createUtteranceError) {
      toast.error(createUtteranceError?.data?.message);
    }
  }, [isCreateUtteranceSuccess, isCreateUtteranceError]);

  useEffect(() => {
    if (isCreateUtteranceResponseSuccess) {
      setUtteranceResponseInput("");
    }
    if (isCreateUtteranceResponseError && createUtteranceResponseError) {
      toast.error(createUtteranceResponseError?.data?.message);
    }
  }, [isCreateUtteranceResponseSuccess, isCreateUtteranceResponseError]);

  useEffect(() => {
    if (selectedUtterance) {
      getUtteranceResponses({ utterance_id: selectedUtterance.id });
    }
  }, [selectedUtterance]);

  return (
    <Row className="g-5">
      <Col sm="3">
        <input
          type="search"
          id="utterance-search-input"
          className="form-control form-control-sm"
          placeholder={"Search or add"}
          onChange={(e) => setUtteranceSearchInput(e.target.value)}
          onKeyDown={(e) => onUtteranceSearchInputKeydown(e)}
          value={utteranceSearchInput}
        />
        <UncontrolledTooltip
          placement="top"
          flip={false}
          target={`utterance-search-input`}
        >
          Press shift + enter to add, esc to cancel
        </UncontrolledTooltip>
        <ul>
          {isGetUtterancesFetching && (
            <Spinner
              className="mt-5"
              style={{ display: "block", margin: "auto" }}
            />
          )}
          {utterancesData
            ?.filter((data) => {
              return data.utterance.name
                .toLowerCase()
                .includes(utteranceSearchInput.toLowerCase());
            })
            ?.map((data, index) => (
              <li
                key={`utterance-${data.utterance.id}`}
                className="d-flex my-3 px-2 py-1"
                style={{
                  cursor: "pointer",
                  borderRadius: "8px",
                  backgroundColor:
                    selectedUtterance?.id === data.utterance.id
                      ? "#f0eaff"
                      : "transparent",
                }}
                onClick={() => onSelectUtterance(data.utterance)}
              >
                <div>{data.utterance.name}</div>
                <div style={{ marginLeft: "auto" }}>{data.total_responses}</div>
              </li>
            ))}
          {isGetUtterancesSuccess && utterancesData?.length === 0 && (
            <li className="d-flex mt-4 justify-content-center">
              <div>No utterances found</div>
            </li>
          )}
        </ul>
      </Col>
      <Col sm="7">
        <Row className="g-gs">
          <Col sm="8">
            <h6>
              {selectedUtterance ? selectedUtterance.name : "Select utterance"}
            </h6>
          </Col>
          <Col sm="4">
            <input
              type="search"
              className="form-control form-control-sm"
              placeholder={""}
              onChange={(e) => {}}
            />
          </Col>
        </Row>
        <PreviewCard className="mt-2 h-min-650px">
          <Row className="g-2">
            <Col sm="6">
              <span>Responses</span>
            </Col>
          </Row>
          <TextareaAutosize
            className="no-resize form-control mt-2"
            minRows={4}
            value={utteranceResponseInput}
            onChange={(e) => setUtteranceResponseInput(e.target.value)}
            onKeyDown={(e) => onUtteranceResponseInputKeydown(e)}
            disabled={!selectedUtterance}
          />
          <Row className="g-2 align-items-center">
            <Col>
              <span>Press Shift + Enter to save, Esc to cancel</span>
            </Col>
          </Row>
          <ul className="g-3">
            <ResponsesList
              isGetUtteranceResponsesSuccess={isGetUtteranceResponsesSuccess}
              isGetUtteranceResponsesFetching={isGetUtteranceResponsesFetching}
              utteranceResponses={utteranceResponses}
              selectedToEditResponse={selectedToEditResponse}
              setSelectedToEditResponse={setSelectedToEditResponse}
            />
          </ul>
        </PreviewCard>
      </Col>
      <Col sm="2">
        <h6>Corresponding Stories</h6>
      </Col>
    </Row>
  );
};

const ResponsesList = ({
  utteranceResponses,
  isGetUtteranceResponsesSuccess,
  isGetUtteranceResponsesFetching,
  selectedToEditResponse,
  setSelectedToEditResponse,
}) => {
  const [hoveredResponse, setHoveredResponse] = useState(null);

  if (isGetUtteranceResponsesFetching) {
    return (
      <Spinner className="mt-5" style={{ display: "block", margin: "auto" }} />
    );
  }

  if (isGetUtteranceResponsesSuccess && utteranceResponses?.length === 0) {
    return (
      <li className="d-flex mt-2 justify-content-center">
        <div>No responses found</div>
      </li>
    );
  }

  if (utteranceResponses) {
    return utteranceResponses
      ?.filter((response) => response.id !== selectedToEditResponse?.id)
      .map((opt, idx) => (
        <li
          key={`${opt.utterance_id}-response-option-${opt.id}`}
          onMouseEnter={() => setHoveredResponse(opt.id)}
          onMouseLeave={() => setHoveredResponse(null)}
          style={{
            backgroundColor:
              hoveredResponse === opt.id ? "#f0eaff" : "transparent",
            borderRadius: "8px",
          }}
        >
          <Row className="g-2">
            <Col sm="10">
              <div className="custom-control custom-control-md custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input form-control"
                  id={`${opt.utterance_id}-response-${opt.id}`}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`${opt.utterance_id}-response-${opt.id}`}
                >
                  {opt.response}
                </label>
              </div>
            </Col>
            {hoveredResponse === opt.id && (
              <Col sm="2" className="align-content-center">
                <Icon
                  name="trash"
                  className="float-right mx-2"
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                />
                <Icon
                  name="edit"
                  className="float-right mx-2"
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedToEditResponse(opt)}
                />
              </Col>
            )}
          </Row>
        </li>
      ));
  }

  return <div></div>;
};
