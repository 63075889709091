import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect, useEffect } from "react";
import ReactDataTable from "../../../components/table/ReactDataTable";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import SimpleBar from "simplebar-react";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Row,
  Col,
  TextInput,
  ModalComponent,
  SelectField,
  PhoneNumberWithCountryCodeInput,
} from "../../../components/Components";
import {
  onChangePageState,
  toggleModal,
  onFormCancel,
} from "../../../redux/features/organisationSlice";
import {
  useCreateOrganisationMutation,
  useGetOrganisationsQuery,
} from "../../../redux/api/organisations/organisationApi";
import { organisationColumns } from "./DataColumns";
import {
  Breadcrumb,
  BreadcrumbItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useFieldArray, useForm } from "react-hook-form";
import { addressTypeOptions } from "../../../constants";
import { toast } from "react-toastify";

const Partners = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.organisations);
  const defaultValues = {
    email: "",
    name: "",
    website: "",
    contact_number_country_calling_code: "SG",
    addresses: [
      {
        name: "",
        address: "",
        type: "",
      },
    ],
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
  });
  const { data, isFetching } = useGetOrganisationsQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
  });
  const {
    fields: addressFields,
    append: addressAppend,
    remove: addressRemove,
  } = useFieldArray({
    control,
    name: "addresses",
    rules: {
      required: "Minimum requirement is 1 row",
      minLength: {
        value: 1,
        message: "Minimum requirement is 1 row",
      },
    },
  });
  const [
    createPartner,
    {
      isLoading: isCreateLoading,
      isError: isCreateError,
      isSuccess: isCreateSuccess,
      error: createError,
    },
  ] = useCreateOrganisationMutation();
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.organisations);
      setTotalItems(data.total);
    }
  }, [data]);

  const onAddBtnClicked = () => {
    dispatch(toggleModal({ type: "add" }));
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(toggleModal({ type: "add" }));
      reset();
    }
    if (isCreateError && createError) {
      toast.error(createError?.data?.message);
    }
  }, [isCreateSuccess, isCreateError]);

  const processData = (data) => {
    data.addresses = data.addresses?.map((e, idx) => {
      return {
        ...data.addresses[idx],
        type: e.type?.value,
        longitude: parseFloat(e.longitude),
        latitude: parseFloat(e.latitude),
      };
    });
    return data;
  };

  const onFormSubmit = async (data) => {
    data = processData(data);
    createPartner(data);
  };

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "edit":
          dispatch(toggleModal({ type: "edit", item: row }));
          reset({
            ...row,
          });
          break;
      case "remove":
        //dispatch delete
        break;
      default:
    }
  };

  return (
    <>
      <Head title="Partners"></Head>
      <Content>
        <Block>
          <BlockHead>
            <Breadcrumb
              tag="nav"
              listTag="div"
              className="breadcrumb-arrow fs-18px"
            >
              <BreadcrumbItem tag="a" href="../">
                Home
              </BreadcrumbItem>
              <BreadcrumbItem active>Partners</BreadcrumbItem>
            </Breadcrumb>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Partners</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle d-none d-md-inline-flex mr-3"
                    color="primary"
                    onClick={() => {
                      onAddBtnClicked();
                    }}
                  >
                    <Icon name="plus"></Icon>
                    <span>Create Partner</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <div className="table-wrapper is-scrollable">
              <ReactDataTable
                data={tableData}
                columns={organisationColumns}
                offset={state.offset}
                itemsPerPage={state.itemsPerPage}
                pagination
                totalItems={totalItems}
                progressPending={isFetching}
                className="nk-tb-list"
                selectableRows
                changePageState={changePageState}
                onRowClicked={onRowClicked}
              />
            </div>
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.add || state.modal.edit}
          backdrop={"static"}
          title={`${state.modal.add ? "Add" : "Edit"} Partner`}
          onModalCancel={() => {
            dispatch(onFormCancel());
            reset(defaultValues);
          }}
        >
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Row className="g-3">
              <TextInput
                name={"email"}
                label={"Email"}
                required
                register={{
                  ...register("email", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
                md="12"
              />
              <TextInput
                name={"name"}
                label={"Name"}
                required
                register={{
                  ...register("name", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
                md="12"
              />
              <PhoneNumberWithCountryCodeInput
                label={"Contact number"}
                nameCode={"contact_number_country_calling_code"}
                nameInputField={"contact_number"}
                register={register}
                control={control}
                errors={errors}
                md="12"
              />
              <TextInput
                name={"website"}
                label={"Website"}
                register={{
                  ...register("website"),
                }}
                errors={errors}
                md="12"
              />
              <Col md="12">
                <label className="form-label">
                  <p>Addresses</p>
                </label>
                <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
                  <div className="table-wrapper table-actions">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" className="w-min-300px">
                            Name <span className="required-asterisk">*</span>
                          </th>
                          <th scope="col" className="w-min-300px">
                            Address <span className="required-asterisk">*</span>
                          </th>
                          <th scope="col" className="w-min-200px">
                            Latitude{" "}
                            <span className="required-asterisk">*</span>
                          </th>
                          <th scope="col" className="w-min-200px">
                            Longitude{" "}
                            <span className="required-asterisk">*</span>
                          </th>
                          <th scope="col" className="w-min-250px">
                            Type <span className="required-asterisk">*</span>
                          </th>
                          <th scope="col" className="w-min-100px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {addressFields.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td>
                                <TextInput
                                  name={`addresses.${index}.name`}
                                  required
                                  register={{
                                    ...register(`addresses.${index}.name`, {
                                      required: "This field is required",
                                    }),
                                  }}
                                  errors={errors}
                                  md="12"
                                />
                              </td>
                              <td>
                                <TextInput
                                  name={`addresses.${index}.address`}
                                  required
                                  register={{
                                    ...register(`addresses.${index}.address`, {
                                      required: "This field is required",
                                    }),
                                  }}
                                  errors={errors}
                                  md="12"
                                />
                              </td>
                              <td>
                                <TextInput
                                  name={`addresses.${index}.latitude`}
                                  required
                                  register={{
                                    ...register(`addresses.${index}.latitude`, {
                                      required: "This field is required",
                                    }),
                                  }}
                                  errors={errors}
                                  md="12"
                                />
                              </td>
                              <td>
                                <TextInput
                                  name={`addresses.${index}.longitude`}
                                  required
                                  register={{
                                    ...register(
                                      `addresses.${index}.longitude`,
                                      {
                                        required: "This field is required",
                                      }
                                    ),
                                  }}
                                  errors={errors}
                                  md="12"
                                />
                              </td>
                              <td>
                                <SelectField
                                  name={`addresses.${index}.type`}
                                  control={control}
                                  menuPortalTarget={document.body}
                                  rules={{ required: "This field is required" }}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      maxWidth: "300px",
                                    }),
                                  }}
                                  options={addressTypeOptions}
                                  errors={errors}
                                  md="12"
                                />
                              </td>
                              <td>
                                {index === addressFields.length - 1 && (
                                  <Icon
                                    onClick={() => {
                                      addressAppend({
                                        name: "",
                                        address: "",
                                        type: "Primary",
                                      });
                                    }}
                                    name="plus-c"
                                    className="fs-22px mr-2"
                                  />
                                )}
                                {((addressFields.length > 1 && index === 0) ||
                                  index !== 0) && (
                                  <Icon
                                    onClick={() => {
                                      addressRemove(index);
                                    }}
                                    name="trash"
                                    className="fs-22px"
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </SimpleBar>
              </Col>

              <Col size="12">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isCreateLoading}
                >
                  {isCreateLoading && <Spinner type="grow" size="sm" />}
                  <span>{state.modal.add ? "Add" : "Edit"}</span>
                </Button>
              </Col>
            </Row>
          </form>
        </ModalComponent>
      </Content>
    </>
  );
};


export default Partners;
