import { useDispatch, useSelector } from "react-redux";
import { useLayoutEffect, useState, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Button, Spinner } from "reactstrap";
import { employmentOverviewColumns, employmentsColumns, workingDaysOptions } from "./DataColumns";
import { toast } from "react-toastify";
import moment from "moment";
import { toggleModal, onChangePageState, onChangeSearchText, onFormCancel } from "../../../redux/features/app-portal/employment/employmentOverviewSlice";
import { WorkingHoursField } from "../../../components/form/WorkingHoursField";
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Col, Icon, ModalComponent, PreviewCard, ReactDataTable, Row, SelectField, TextInput, TextareaInput } from "../../../components/Components";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { useCreateEmploymentMutation, useCreateProScheduleMutation, useDeleteEmploymentMutation, useGetEmploymentDetailsQuery, useGetEmploymentOverviewQuery, useGetProSchedulesQuery, useUpdateEmploymentMutation } from "../../../redux/api/employment-management/employmentApi";

const EmploymentOverview = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.employmentOverview);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm();
  const { data, isFetching, isSuccess } = useGetEmploymentOverviewQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
  });
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.data);
      setTotalItems(data.total);
    }
  }, [data]);

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "remove":
        //dispatch delete
        break;
      case "details":
        dispatch(toggleModal({ type: "details", item: row }));
        break;
    }
  };
  return (
    <>
      <Head title="Employment Overview" />
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Employment Overview</BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <ReactDataTable
              keyField="user_id"
              data={tableData}
              columns={employmentOverviewColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              pagination
              totalItems={totalItems}
              progressPending={isFetching}
              className="nk-tb-list"
              selectableRows
              changePageState={changePageState}
              onRowClicked={onRowClicked}
            />
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.details}
          toggle={() => dispatch(toggleModal({ type: "details" }))}
          title={"Pro Schedule Details"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {state.modal.item && (
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text">Pro Schedule Id</span>
                <span className="caption-text">{state.modal.item.id}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Pro Email</span>
                <span className="caption-text">{state.modal.item.email}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">PWD Email</span>
                <span className="caption-text">
                  {state.modal.item.employment_email}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Created Time</span>
                <span className="caption-text">
                  {state.modal.item.created_time}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Last Updated Time</span>
                <span className="caption-text">
                  {state.modal.item.last_updated_time}
                </span>
              </Col>
            </Row>
          )}
        </ModalComponent>
      </Content>
    </>
  );
};
export default EmploymentOverview;
