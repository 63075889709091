import moment from "moment";
import { Link } from "react-router-dom";
import { contractTypeOptions, salaryUnitOptions, workTypeOptions } from "../../../constants";
import { BatchMenuComponent, Icon, TooltipComponent } from "../../../components/Components";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { useSelector } from "react-redux";

export const employmentOverviewColumns = [
  {
    name: "User",
    selector: (row) => row.name,
    sortable: true,
    compact: true,
    minWidth: "200px",
    style: { paddingRight: "20px" },
    cell: (row) => <Link to={`/users/${row.user_id}`}><span>{row.name}</span></Link>,
  },
  {
    name: "Professional",
    minWidth: "200px",
    compact: true,
    cell: (row) => (
      <div>
        {row.professionals?.map((professional, index) => {
          return (
            <div key={index}>
              <span>{`${professional.name ?? professional.email} - ${professional.role ?? "Unknown role"}`}</span>
            </div>
          );
        })}
      </div>
    ),
  },
  {
    name: "Employment start",
    selector: (row) => row.employment.start_at,
    sortable: true,
    minWidth: "120px",
    cell: (row) => <span>{row.employment.start_at}</span>,
    hide: 480,
  },
  {
    name: "Employment end",
    selector: (row) => row.employment.end_at,
    sortable: true,
    minWidth: "120px",
    cell: (row) => <span>{row.employment.end_at}</span>,
    hide: 480,
  },
  {
    name: "Support start",
    selector: (row) => row.workplace_name,
    minWidth: "120px",
    cell: (row) => <span>{row.employment.end_at}</span>,
    hide: 480,
  },
  {
    name: "Support end",
    selector: (row) => row.employment.end_at,
    minWidth: "120px",
    cell: (row) => <span>{row.employment.end_at}</span>,
    hide: 480,
  },
  {
    name: "Hours supported",
    selector: (row) => row.workplace_name,
    minWidth: "120px",
    cell: (row) => <span>{row.workplace_name}</span>,
    hide: 480,
  },
];

export const employmentDetailsColumns = [
  // {
  //   name: "User ID",
  //   // selector: (row) => row.id,
  //   compact: true,
  //   style: { paddingRight: "20px" },
  //   cell: (row) => <span>{row.id}</span>,
  // },
  {
    name: "Name",
    selector: (row) => {
      let name = "";
      if (row.first_name) {
        name += `${row.first_name} `;
      }
      if (row.last_name) {
        name += row.last_name;
      }
      return name;
    },
    sortable: true,
    minWidth: "200px",
    cell: (row) => {
      let name = "";
      if (row.first_name) {
        name += `${row.first_name} `;
      }
      if (row.last_name) {
        name += row.last_name;
      }
      return <span>{name}</span>;
    },
    hide: "md",
  },
  {
    name: "Employer Name",
    selector: (row) => row.employer,
    sortable: true,
    minWidth: "200px",
    cell: (row) => <span>{row.employer}</span>,
    hide: "md",
  },
  {
    name: "Contract Type",
    selector: (row) => row.contract_type,
    sortable: true,
    minWidth: "150px",
    cell: (row) => {
      var t = contractTypeOptions.find((option) => option.value === row.contract_type);
      return <span>{t ? t.label : ""}</span>;
    },
    hide: "md",
  },
  {
    name: "Work Type",
    selector: (row) => row.work_type,
    sortable: true,
    minWidth: "150px",
    cell: (row) => {
      var t = workTypeOptions.find((option) => option.value === row.work_type);
      return <span>{t ? t.label : ""}</span>;
    },
    hide: "md",
  },
  {
    name: "Working Days & Hours",
    minWidth: "200px",
    cell: (row) => {
      if (row.same_everyday && row.working_days.length > 0) {
        var msg = "";
        for (let i = 0; i < row.working_days.length; i++) {
          let f = workingDaysOptions.find(
            (option) =>
              parseInt(option.value) === row.working_days[i].day_of_week
          );
          if (f) {
            msg += f.abbr;
          }
          if (i < row.working_days.length - 1) {
            msg += ", ";
          }
        }
        return (
          <span>{`${msg}: ${row.working_days[0].working_time_from_text} - ${row.working_days[0].working_time_to_text}`}</span>
        );
      }

      if (!row.same_everyday && row.working_days.length > 0) {
        return (
          <div>
            {row.working_days.map((day, index) => {
              let f = workingDaysOptions.find(
                (option) => parseInt(option.value) === day.day_of_week
              );
              if (f) {
                return (
                  <div key={index}>
                    <span>{f.abbr}</span>
                    <span>{`: ${day.working_time_from_text} - ${day.working_time_to_text}`}</span>
                  </div>
                );
              }
            })}
          </div>
        )
      }
    },
    hide: "md",
  },
  {
    name: "Salary",
    // selector: (row) => row.salary,
    // sortable: true,
    cell: (row) => <span>{row.salary}</span>,
    minWidth: "150px",
    hide: "md",
  },
  {
    name: "Salary Unit / Frequency",
    // selector: (row) => row.email,
    cell: (row) => {
      var t = salaryUnitOptions.find((option) => option.value === row.salary_unit);
      return <span>{t ? t.label : ""}</span>;
    },
    minWidth: "150px",
    hide: "md",
  },
  {
    name: "Start Date",
    selector: (row) => row.start_date,
    sortable: true,
    cell: (row) => <span>{row.start_date ? moment(row.start_date).format("DD-MM-YYYY") : ""}</span>,
    minWidth: "150px",
    hide: "md",
  },
  {
    name: "End Date",
    selector: (row) => row.end_date,
    sortable: true,
    cell: (row) => <span>{row.end_date ? moment(row.end_date).format("DD-MM-YYYY") : ""}</span>,
    minWidth: "150px",
    hide: "md",
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => (
      <span>{moment(row.created_time).format("DD-MM-YYYY, hh:mm:ss a")}</span>
    ),
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => (
      <span>
        {moment(row.last_updated_time).format("DD-MM-YYYY, hh:mm:ss a")}
      </span>
    ),
    hide: "md",
  },
  {
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  }
];

export const employmentSupportColumns = [
  {
    name: "Date",
    selector: (row) => row.start_at,
    minWidth: "150px",
    sortable: true,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{moment(row.start_at).format("DD-MM-YYYY")}</span>,
  },
  {
    name: "Start Time",
    minWidth: "150px",
    cell: (row) => <span>{moment(row.start_at).format("hh:mm:ss a")}</span>,
    hide: "md",
  },
  {
    name: "End Time",
    minWidth: "150px",
    cell: (row) => <span>{moment(row.end_at).format("hh:mm:ss a")}</span>,
    hide: "md",
  },
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
    minWidth: "200px",
    cell: (row) => <span>{row.name}</span>,
    hide: "md",
  },
  {
    name: "Employment Details",
    minWidth: "150px",
    cell: (row) => <span>{row.employment_details?.id}</span>,
    hide: "md",
  },
  {
    name: "Professional",
    selector: (row) => row.professional_name,
    sortable: true,
    minWidth: "150px",
    cell: (row) => <span>{row.professional_name}</span>,
    hide: "md",
  },
  {
    name: "From",
    selector: (row) => row.workplace_name,
    sortable: true,
    minWidth: "120px",
    cell: (row) => <span>{row.workplace_name}</span>,
    hide: "md",
  },
  {
    name: "Location",
    selector: (row) => row.location,
    sortable: true,
    minWidth: "120px",
    cell: (row) => <span>{row.location}</span>,
    hide: "md",
  },
  {
    name: "Support Categories",
    minWidth: "120px",
    cell: (row) => {
      var list = [];
      for (var i = 0; i < row?.metrics?.length; i++) {
        if (list.indexOf(row.metrics[i].type) === -1) {
          list.push(row.metrics[i].type);
        }
      }
      return <span>{list.join(", ")}</span>;
    },
    hide: "md",
  },
  {
    name: "Observation grading",
    selector: (row) => row.observation_grading,
    minWidth: "120px",
    cell: (row) => <span>{row.observation_grading}</span>,
    hide: "md",
  },
  {
    name: "Observation",
    selector: (row) => row.workplace_name,
    minWidth: "120px",
    cell: (row) => {
      return (
        <div>
          {row.observations &&
            row.observations.map((observation, index) => {
              var text = "";
              //Limit the text to 50 characters
              if (observation.description.length > 50) {
                text = observation.description.substring(0, 50) + "...";
              } else {
                text = observation.description;
              }

              return (
                <div key={index}>
                  <span>{text}</span>
                </div>
              );
            })}
        </div>
      );
    },
    hide: "md",
  },
  {
    name: "Intervention",
    minWidth: "120px",
    cell: (row) => {
      var text = "";
      //Limit the text to 50 characters
      if (row.intervention?.length > 50) {
        text = row.intervention?.substring(0, 50) + "...";
      } else {
        text = row.intervention;
      }

      return <span>{text}</span>;
    },
    hide: "md",
  },
  {
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  }
];

export const workingDaysOptions = [
  {
    value: "0",
    label: "Sunday",
    abbr: "Sun",
  },
  {
    value: "1",
    label: "Monday",
    abbr: "Mon",
  },
  {
    value: "2",
    label: "Tuesday",
    abbr: "Tue",
  },
  {
    value: "3",
    label: "Wednesday",
    abbr: "Wed",
  },
  {
    value: "4",
    label: "Thursday",
    abbr: "Thurs",
  },
  {
    value: "5",
    label: "Friday",
    abbr: "Fri",
  },
  {
    value: "6",
    label: "Saturday",
    abbr: "Sat",
  },
  {
    value: "7",
    label: "Sunday",
    abbr: "Sun",
  },
];
