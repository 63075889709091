import {
  Button,
  PreviewCard,
  Row,
  Col,
  Icon,
} from "../../../components/Components";
import TextareaAutosize from "react-textarea-autosize";
import {
  useCreateIntentExamplesMutation,
  useCreateIntentMutation,
  useGetIntentsQuery,
  useLazyGetIntentExamplesQuery,
} from "../../../redux/api/chatbot/chatbotApi";
import { Spinner, Tooltip, UncontrolledTooltip } from "reactstrap";
import { useEffect } from "react";
import { useState } from "react";
import { set } from "react-hook-form";
import { toast } from "react-toastify";

export const IntentsComponent = ({}) => {
  const {
    data: intentsData,
    isFetching: isGetIntentsFetching,
    isSuccess: isGetIntentsSuccess,
    isError: isGetIntentsError,
  } = useGetIntentsQuery({
    is_get_examples: true,
  });
  const [
    getIntentExamples,
    {
      data: intentExamples,
      isSuccess: isGetIntentExamplesSuccess,
      isFetching: isGetIntentExamplesFetching,
      isError: isGetIntentExamplesError,
    },
  ] = useLazyGetIntentExamplesQuery();
  const [
    createIntent,
    {
      isLoading: isCreateIntentLoading,
      isError: isCreateIntentError,
      isSuccess: isCreateIntentSuccess,
      error: createIntentError,
    },
  ] = useCreateIntentMutation();
  const [
    createIntentExample,
    {
      isLoading: isCreateIntentExampleLoading,
      isError: isCreateIntentExampleError,
      isSuccess: isCreateIntentExampleSuccess,
      error: createIntentExampleError,
    },
  ] = useCreateIntentExamplesMutation();
  const [intentSearchInput, setIntentSearchInput] = useState("");
  const [selectedIntent, setSelectedIntent] = useState(null);
  const [intentExampleInput, setIntentExampleInput] = useState("");
  const [selectedToEditExample, setSelectedToEditExample] = useState(null);

  const onIntentExampleInputKeydown = (evt) => {
    if (evt.key === "Enter" && evt.shiftKey) {
      if (isCreateIntentExampleLoading) return;

      if (intentExampleInput) {
        createIntentExample({
          intent_id: selectedIntent.id,
          example: intentExampleInput,
        });
      }
    }

    if (evt.key === "Escape") {
      if (selectedToEditExample) {
        setSelectedToEditExample(null);
      }
    }
  };

  const onIntentSearchInputKeydown = (evt) => {
    if (evt.key === "Enter" && evt.shiftKey) {
      if (isCreateIntentLoading) return;

      if (intentSearchInput) {
        if (intentsData?.find((data) => data.intent.name === intentSearchInput)) {
          toast.error("Intent already exists");
        } else {
          createIntent({ name: intentSearchInput });
        }
      }
    }

    if (evt.key === "Escape") {
      setIntentSearchInput("");
    }
  };

  const onSelectIntent = (intent) => {
    setSelectedIntent(intent);
  };

  useEffect(() => {
    if (selectedToEditExample) {
      setIntentExampleInput(selectedToEditExample.example);
    } else {
      setIntentExampleInput("");
    }
  }, [selectedToEditExample]);

  useEffect(() => {
    if (isCreateIntentSuccess) {
      setIntentSearchInput("");
    }
    if (isCreateIntentError && createIntentError) {
      toast.error(createIntentError?.data?.message);
    }
  }, [isCreateIntentSuccess, isCreateIntentError]);

  useEffect(() => {
    if (isCreateIntentExampleSuccess) {
      setIntentExampleInput("");
    }
    if (isCreateIntentExampleError && createIntentExampleError) {
      toast.error(createIntentExampleError?.data?.message);
    }
  }, [isCreateIntentExampleSuccess, isCreateIntentExampleError]);

  useEffect(() => {
    if (selectedIntent) {
      getIntentExamples({ intent_id: selectedIntent.id });
    }
  }, [selectedIntent]);

  return (
    <Row className="g-5">
      <Col sm="3">
        <input
          type="search"
          id="intent-search-input"
          className="form-control form-control-sm"
          placeholder={"Search or add"}
          onChange={(e) => setIntentSearchInput(e.target.value)}
          onKeyDown={(e) => onIntentSearchInputKeydown(e)}
          value={intentSearchInput}
        />
        <UncontrolledTooltip
          placement="top"
          flip={false}
          target={`intent-search-input`}
        >
          Press shift + enter to add, esc to cancel
        </UncontrolledTooltip>
        <ul>
          {isGetIntentsFetching && (
            <Spinner
              className="mt-5"
              style={{ display: "block", margin: "auto" }}
            />
          )}
          {intentsData
            ?.filter((data) => {
              return data.intent.name
                .toLowerCase()
                .includes(intentSearchInput.toLowerCase());
            })
            ?.map((data, index) => (
              <li
                key={`intent-${data.intent.id}`}
                className="d-flex my-3 px-2 py-1"
                style={{
                  cursor: "pointer",
                  borderRadius: "8px",
                  backgroundColor:
                    selectedIntent?.id === data.intent.id
                      ? "#f0eaff"
                      : "transparent",
                }}
                onClick={() => onSelectIntent(data.intent)}
              >
                <div>{data.intent.name}</div>
                <div style={{ marginLeft: "auto" }}>{data.total_examples}</div>
              </li>
            ))}
          {isGetIntentsSuccess && intentsData?.length === 0 && (
            <li className="d-flex mt-4 justify-content-center">
              <div>No intents found</div>
            </li>
          )}
        </ul>
      </Col>
      <Col sm="7">
        <Row className="g-gs">
          <Col sm="8">
            <h6>{selectedIntent ? selectedIntent.name : "Select intent"}</h6>
          </Col>
          <Col sm="4">
            <input
              type="search"
              className="form-control form-control-sm"
              placeholder={""}
              onChange={(e) => {}}
            />
          </Col>
        </Row>
        <PreviewCard className="mt-2 h-min-650px">
          <Row className="g-2">
            <Col sm="6">
              <span>Examples</span>
            </Col>
          </Row>
          <TextareaAutosize
            className="no-resize form-control mt-2"
            minRows={4}
            value={intentExampleInput}
            onChange={(e) => setIntentExampleInput(e.target.value)}
            onKeyDown={(e) => onIntentExampleInputKeydown(e)}
            disabled={!selectedIntent}
          />
          <Row className="g-2 align-items-center">
            <Col>
              <span>Press Shift + Enter to save, Esc to cancel</span>
            </Col>
            <Col>
              <Button>Add Entity For</Button>
            </Col>
          </Row>
          <ul className="g-3">
            <ExamplesList
              isGetIntentExamplesSuccess={isGetIntentExamplesSuccess}
              isGetIntentExamplesFetching={isGetIntentExamplesFetching}
              intentExamples={intentExamples}
              selectedToEditExample={selectedToEditExample}
              setSelectedToEditExample={setSelectedToEditExample}
            />
          </ul>
        </PreviewCard>
      </Col>
      <Col sm="2">
        <h6>Corresponding Stories</h6>
      </Col>
    </Row>
  );
};

const ExamplesList = ({
  intentExamples,
  isGetIntentExamplesSuccess,
  isGetIntentExamplesFetching,
  selectedToEditExample,
  setSelectedToEditExample,
}) => {
  const [hoveredExample, setHoveredExample] = useState(null);

  if (isGetIntentExamplesFetching) {
    return (
      <Spinner className="mt-5" style={{ display: "block", margin: "auto" }} />
    );
  }

  if (isGetIntentExamplesSuccess && intentExamples?.length === 0) {
    return (
      <li className="d-flex mt-2 justify-content-center">
        <div>No examples found</div>
      </li>
    );
  }

  if (intentExamples) {
    return intentExamples?.filter((example) => example.id !== selectedToEditExample?.id).map((opt, idx) => (
      <li
        key={`${opt.intent_id}-example-option-${opt.id}`}
        onMouseEnter={() => setHoveredExample(opt.id)}
        onMouseLeave={() => setHoveredExample(null)}
        style={{
          backgroundColor:
            hoveredExample === opt.id ? "#f0eaff" : "transparent",
          borderRadius: "8px",
        }}
      >
        <Row className="g-2">
          <Col sm="10">
            <div className="custom-control custom-control-md custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input form-control"
                id={`${opt.intent_id}-example-${opt.id}`}
              />
              <label
                className="custom-control-label"
                htmlFor={`${opt.intent_id}-example-${opt.id}`}
              >
                {opt.example}
              </label>
            </div>
          </Col>
          {hoveredExample === opt.id && (
            <Col sm="2" className="align-content-center">
              <Icon
                name="trash"
                className="float-right mx-2"
                style={{
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              />
              <Icon
                name="edit"
                className="float-right mx-2"
                style={{
                  fontSize: "18px",
                  cursor: "pointer",
                }}
                onClick={() => setSelectedToEditExample(opt)}
              />
            </Col>
          )}
        </Row>
      </li>
    ));
  }

  return <div></div>;
};
