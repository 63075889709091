import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import moment from "moment";
import { Icon, TooltipComponent } from "../../../components/Components";
import { purposeOptions, workTypeOptions } from "../../../constants";

export const locationTrackingColumns = [
  {
    name: "Platform",
    // selector: (row) => row.id,
    compact: true,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.id}</span>,
  },
  {
    name: "Track by (Timning/Distance)",
    // selector: (row) => row.email,
    minWidth: "120px",
    cell: (row) => <span></span>,
    hide: "md",
  },
  {
    name: "Track value",
    selector: (row) => row.workplace_name,
    minWidth: "120px",
    cell: (row) => <span>{row.workplace_name}</span>,
    hide: "md",
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    minWidth: "120px",
    cell: (row) => (
      <span>{moment(row.created_time).format("DD-MM-YYYY, hh:mm:ss a")}</span>
    ),
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    minWidth: "120px",
    cell: (row) => (
      <span>
        {moment(row.last_updated_time).format("DD-MM-YYYY, hh:mm:ss a")}
      </span>
    ),
    hide: "md",
  }
];
