import { api } from "../api";

export const employmentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEmploymentOverview: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/employment-overview",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          data: response.data,
          total: response.total,
        };
      },
      providesTags: ["EmploymentOverview"],
    }),
    getEmploymentDetails: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/employment",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          employments: response.data,
          total: response.total,
        };
      },
      providesTags: ["Employments"],
    }),
    createEmployment: builder.mutation({
      query: (data) => ({
        url: "admin/v1/employment",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Employments"],
    }),
    updateEmployment: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/employment",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["Employments"],
    }),
    deleteEmployment: builder.mutation({
      query: (data) => ({
        url: "admin/v1/employment",
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Employments"],
    }),
    getWorkplaces: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/workplace-detail",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          workplaces: response.data,
          total: response.total,
        };
      },
      providesTags: ["Workplaces"],
    }),
    createWorkplace: builder.mutation({
      query: (data) => ({
        url: "admin/v1/workplace-detail",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Workplaces"],
    }),
    getProSchedules: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/pro-schedule",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          proSchedules: response.data,
          total: response.total,
        };
      },
      providesTags: ["Pro-Schedules"],
    }),
    createProSchedule: builder.mutation({
      query: (data) => ({
        url: "admin/v1/pro-schedule",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Pro-Schedules"],
    }),
  }),
});

export const {
  useGetEmploymentOverviewQuery,
  useLazyGetEmploymentOverviewQuery,
  useGetEmploymentDetailsQuery,
  useLazyGetEmploymentDetailsQuery,
  useCreateEmploymentMutation,
  useUpdateEmploymentMutation,
  useDeleteEmploymentMutation,
  useGetWorkplacesQuery,
  useLazyGetWorkplacesQuery,
  useCreateWorkplaceMutation,
  useGetProSchedulesQuery,
  useCreateProScheduleMutation,
} = employmentApi;
