import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect } from "react";
import ReactDataTable from "../../../components/table/ReactDataTable";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
} from "../../../components/Components";
import { checkInOutLogColumns, locationLogColumns } from "./DataColumns";
import { useGetGoalFrequenciesQuery } from "../../../redux/api/goal-management/goalApi";
import { onChangePageState, toggleModal } from "../../../redux/features/app-portal/logs/locationLogSlice";
import { useGetLocationTrackingsQuery } from "../../../redux/api/support-management/supportApi";

const LocationLog = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.locationLog);
  const { data, isFetching, isSuccess } = useGetLocationTrackingsQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
  });
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.locationTrackings);
      setTotalItems(data.total);
    }
  }, [data]);

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "remove":
        //dispatch delete
        break;
      case "details":
        dispatch(toggleModal({ type: "details", item: row }));
        break;
      case "open-map":
        window.open(`https://maps.google.com?q=${row.coordinates?.latitude},${row.coordinates?.longitude}`, '_blank', 'noopener,noreferrer');
        break;
    }
  };

  return (
    <>
      <Head title="Location log"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Location log</BlockTitle>
              </BlockHeadContent>
              {/* <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle btn-icon d-md-none"
                    color="primary"
                    onClick={() => {
                      //   toggle("add");
                    }}
                  >
                    <Icon name="plus"></Icon>
                  </Button>
                  <Button
                    className="toggle d-none d-md-inline-flex"
                    color="primary"
                    onClick={() => {
                      //   toggle("add");
                    }}
                  >
                    <Icon name="plus"></Icon>
                    <span>Add Location log</span>
                  </Button>
                </div>
              </BlockHeadContent> */}
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <ReactDataTable
              data={tableData}
              columns={locationLogColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              pagination
              totalItems={totalItems}
              progressPending={isFetching}
              className="nk-tb-list"
              selectableRows
              changePageState={changePageState}
              onRowClicked={onRowClicked}
            />
          </PreviewCard>
        </Block>
      </Content>
    </>
  );
};

export default LocationLog;
