import { api } from "../api";

export const partnerAccount = api.injectEndpoints({
  endpoints: (builder) => ({
    getPartnerAccounts: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/partner-accounts",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          accounts: response.data,
          total: response.total,
        };
      },
      providesTags: ["PartnerAccounts"],
    }),
  }),
});

export const { useGetPartnerAccountsQuery, useLazyGetPartnerAccountsQuery } = partnerAccount;
