import { api } from "../api";

export const partnerAccountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createPartnerAccounts: builder.mutation({
      query: (data) => ({
        url: "admin/v1/partner-accounts",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PartnerAccounts"],
    }),
    updatePartnerAccount: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/partner-accounts",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["PartnerAccounts"],
    }),
  }),
});

export const {
  useCreatePartnerAccountsMutation,
  useUpdatePartnerAccountMutation,
} = partnerAccountApi;
