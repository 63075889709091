import { useEffect, useState } from "react";
import {
  useCreateIntentExamplesMutation,
  useCreateUtteranceResponsesMutation,
  useLazyGetIntentExamplesQuery,
  useLazyGetUtteranceResponsesQuery,
} from "../../../redux/api/chatbot/chatbotApi";
import { Button, Spinner } from "reactstrap";
import { Col, Icon, Row } from "../../../components/Components";
import TextareaAutosize from "react-textarea-autosize";
import { toast } from "react-toastify";

export const Sidebar = ({
  selectedComponentNode,
  isVisible,
  onCloseSidebarClicked,
}) => {
  return (
    <div
      style={{
        transform: isVisible ? "none" : "translateX(120%)",
        transition: "transform 0.3s",
        position: "absolute",
        top: 0,
        right: 0,
        width: "650px",
        height: "100%",
        maxHeight: "100%",
        zIndex: 999,
        backgroundColor: "white",
      }}
    >
      <Button
        color="light"
        className="btn-round btn-icon"
        style={{
          position: "absolute",
          top: 20,
          left: -20,
        }}
        onClick={() => onCloseSidebarClicked()}
      >
        <Icon name="cross"></Icon>
      </Button>
      <div className="px-4 py-4">
        {selectedComponentNode?.type === "intents" && (
          <IntentsExamplesSidebarContent node={selectedComponentNode} />
        )}
        {selectedComponentNode?.type === "utterances" && (
          <UtteranceResponsesSidebarContent node={selectedComponentNode} />
        )}
      </div>
    </div>
  );
};

const IntentsExamplesSidebarContent = ({ node }) => {
  const [intentExampleInput, setIntentExampleInput] = useState("");
  const [selectedToEditExample, setSelectedToEditExample] = useState(null);
  const [
    getIntentExamples,
    {
      data: intentExamples,
      isFetching: isGetIntentExamplesFetching,
      isSuccess: isGetIntentExamplesSuccess,
    },
  ] = useLazyGetIntentExamplesQuery();
  const [
    createIntentExample,
    {
      isLoading: isCreateIntentExampleLoading,
      isError: isCreateIntentExampleError,
      isSuccess: isCreateIntentExampleSuccess,
      error: createIntentExampleError,
    },
  ] = useCreateIntentExamplesMutation();

  useEffect(() => {
    if (node) {
      if (node.type === "intents") {
        getIntentExamples({ intent_id: node.item.id });
      }
    }
  }, [node]);

  const onIntentExampleInputKeydown = (evt) => {
    if (evt.key === "Enter" && evt.shiftKey) {
      if (isCreateIntentExampleLoading) return;

      if (intentExampleInput) {
        createIntentExample({
          intent_id: node.item.id,
          example: intentExampleInput,
        });
      }
    }

    if (evt.key === "Escape") {
      if (selectedToEditExample) {
        setSelectedToEditExample(null);
      }
    }
  };

  return (
    <>
      <h5>{node.item.name} - Examples</h5>
      <TextareaAutosize
        className="no-resize form-control mt-2"
        minRows={4}
        value={intentExampleInput}
        onChange={(e) => setIntentExampleInput(e.target.value)}
        onKeyDown={(e) => onIntentExampleInputKeydown(e)}
      />
      <Row className="g-2 align-items-center">
        <Col>
          <span>Press Shift + Enter to save, Esc to cancel</span>
        </Col>
        {/* <Col>
          <Button>Add Entity For</Button>
        </Col> */}
      </Row>
      <ul className="g-3">
        <ExamplesList
          isGetIntentExamplesSuccess={isGetIntentExamplesSuccess}
          isGetIntentExamplesFetching={isGetIntentExamplesFetching}
          intentExamples={intentExamples}
          selectedToEditExample={selectedToEditExample}
          setSelectedToEditExample={setSelectedToEditExample}
        />
      </ul>
    </>
  );
};

const ExamplesList = ({
  intentExamples,
  isGetIntentExamplesSuccess,
  isGetIntentExamplesFetching,
  selectedToEditExample,
  setSelectedToEditExample,
}) => {
  const [hoveredExample, setHoveredExample] = useState(null);

  if (isGetIntentExamplesFetching) {
    return (
      <Spinner className="mt-5" style={{ display: "block", margin: "auto" }} />
    );
  }

  if (isGetIntentExamplesSuccess && intentExamples?.length === 0) {
    return (
      <li className="d-flex mt-2 justify-content-center">
        <div>No examples found</div>
      </li>
    );
  }

  if (intentExamples) {
    return intentExamples
      ?.filter((example) => example.id !== selectedToEditExample?.id)
      .map((opt, idx) => (
        <li
          key={`${opt.intent_id}-example-option-${opt.id}`}
          onMouseEnter={() => setHoveredExample(opt.id)}
          onMouseLeave={() => setHoveredExample(null)}
          style={{
            backgroundColor:
              hoveredExample === opt.id ? "#f0eaff" : "transparent",
            borderRadius: "8px",
          }}
        >
          <Row className="g-2">
            <Col sm="10">
              <div className="custom-control custom-control-md custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input form-control"
                  id={`${opt.intent_id}-example-${opt.id}`}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`${opt.intent_id}-example-${opt.id}`}
                >
                  {opt.example}
                </label>
              </div>
            </Col>
            {hoveredExample === opt.id && (
              <Col sm="2" className="align-content-center">
                <Icon
                  name="trash"
                  className="float-right mx-2"
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                />
                <Icon
                  name="edit"
                  className="float-right mx-2"
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedToEditExample(opt)}
                />
              </Col>
            )}
          </Row>
        </li>
      ));
  }

  return <div></div>;
};

const UtteranceResponsesSidebarContent = ({ node }) => {
  const [utteranceResponseInput, setUtteranceResponseInput] = useState("");
  const [selectedToEditResponse, setSelectedToEditResponse] = useState(null);
  const [
    getUtteranceResponses,
    {
      data: utteranceResponses,
      isFetching: isGetUtteranceResponsesFetching,
      isSuccess: isGetUtteranceResponsesSuccess,
    },
  ] = useLazyGetUtteranceResponsesQuery();
  const [
    createUtteranceResponse,
    {
      isLoading: isCreateUtteranceResponseLoading,
      isError: isCreateUtteranceResponseError,
      isSuccess: isCreateUtteranceResponseSuccess,
      error: createUtteranceResponseError,
    },
  ] = useCreateUtteranceResponsesMutation();

  useEffect(() => {
    if (node) {
      if (node.type === "utterances") {
        getUtteranceResponses({ utterance_id: node.item.id });
      }
    }
  }, [node]);

  useEffect(() => {
    if (isCreateUtteranceResponseSuccess) {
      setUtteranceResponseInput("");
    }
    if (isCreateUtteranceResponseError && createUtteranceResponseError) {
      toast.error(createUtteranceResponseError.data?.message);
    }
  }, [isCreateUtteranceResponseSuccess, isCreateUtteranceResponseError]);

  const onUtteranceResponseInputKeydown = (evt) => {
    if (evt.key === "Enter" && evt.shiftKey) {
      if (isCreateUtteranceResponseLoading) return;

      if (utteranceResponseInput) {
        createUtteranceResponse({
          utterance_id: node.item.id,
          response: utteranceResponseInput,
        });
      }
    }

    if (evt.key === "Escape") {
      if (selectedToEditResponse) {
        setSelectedToEditResponse(null);
      }
    }
  };

  return (
    <>
      <h5>{node.item.name} - Responses</h5>
      <TextareaAutosize
        className="no-resize form-control mt-2"
        minRows={4}
        value={utteranceResponseInput}
        onChange={(e) => setUtteranceResponseInput(e.target.value)}
        onKeyDown={(e) => onUtteranceResponseInputKeydown(e)}
      />
      <Row className="g-2 align-items-center">
        <Col>
          <span>Press Shift + Enter to save, Esc to cancel</span>
        </Col>
        {/* <Col>
          <Button>Add Entity For</Button>
        </Col> */}
      </Row>
      <ul className="g-3">
        <ResponsesList
          isGetUtteranceResponsesSuccess={isGetUtteranceResponsesSuccess}
          isGetUtteranceResponsesFetching={isGetUtteranceResponsesFetching}
          utteranceResponses={utteranceResponses}
          selectedToEditResponse={selectedToEditResponse}
          setSelectedToEditResponse={setSelectedToEditResponse}
        />
      </ul>
    </>
  );
};

const ResponsesList = ({
  utteranceResponses,
  isGetUtteranceResponsesSuccess,
  isGetUtteranceResponsesFetching,
  selectedToEditResponse,
  setSelectedToEditResponse,
}) => {
  const [hoveredResponse, setHoveredResponse] = useState(null);

  if (isGetUtteranceResponsesFetching) {
    return (
      <Spinner className="mt-5" style={{ display: "block", margin: "auto" }} />
    );
  }

  if (isGetUtteranceResponsesSuccess && utteranceResponses?.length === 0) {
    return (
      <li className="d-flex mt-2 justify-content-center">
        <div>No responses found</div>
      </li>
    );
  }

  if (utteranceResponses) {
    return utteranceResponses
      ?.filter((response) => response.id !== selectedToEditResponse?.id)
      .map((opt, idx) => (
        <li
          key={`${opt.utterance_id}-response-option-${opt.id}`}
          onMouseEnter={() => setHoveredResponse(opt.id)}
          onMouseLeave={() => setHoveredResponse(null)}
          style={{
            backgroundColor:
              hoveredResponse === opt.id ? "#f0eaff" : "transparent",
            borderRadius: "8px",
          }}
        >
          <Row className="g-2">
            <Col sm="10">
              <div className="custom-control custom-control-md custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input form-control"
                  id={`${opt.utterance_id}-response-${opt.id}`}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`${opt.utterance_id}-response-${opt.id}`}
                >
                  {opt.response}
                </label>
              </div>
            </Col>
            {hoveredResponse === opt.id && (
              <Col sm="2" className="align-content-center">
                <Icon
                  name="trash"
                  className="float-right mx-2"
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                />
                <Icon
                  name="edit"
                  className="float-right mx-2"
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedToEditResponse(opt)}
                />
              </Col>
            )}
          </Row>
        </li>
      ));
  }

  return <div></div>;
};
