import { api } from "../api";

export const supportApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCommunityPosts: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/community-post",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          posts: response.data,
          total: response.total,
        };
      },
      providesTags: ["CommunityPosts"],
    }),
    createCommunityPost: builder.mutation({
      query: (data) => ({
        url: "admin/v1/community-post",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["CommunityPosts"],
    }),
  }),
});

export const { useGetCommunityPostsQuery, useCreateCommunityPostMutation } =
  supportApi;
