import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect } from "react";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  ReactDataTable,
} from "../../../components/Components";
import { reminderAndNotificationsColumns } from "./DataColumns";
import { onChangePageState } from "../../../redux/features/app-portal/support/reminderAndNotificationSlice";
import { useGetGoalFrequenciesQuery } from "../../../redux/api/goal-management/goalApi";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";

const ReminderAndNotifications = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.reminderAndNotification);
  // const { data, isFetching, isSuccess } = useGetGoalFrequenciesQuery({
  //   offset: state.offset,
  //   limit: state.itemsPerPage,
  //   total: true,
  // });
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  // useLayoutEffect(() => {
  //   if (data) {
  //     setTableData(data.goalFrequencies);
  //     setTotalItems(data.total);
  //   }
  // }, [data]);

  return (
    <>
      <Head title="Reminder & Notifications"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Reminder & Notifications</BlockTitle>
              </BlockHeadContent>
              {/* <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle btn-icon d-md-none"
                    color="primary"
                    onClick={() => {
                      //   toggle("add");
                    }}
                  >
                    <Icon name="plus"></Icon>
                  </Button>
                  <Button
                    className="toggle d-none d-md-inline-flex"
                    color="primary"
                    onClick={() => {
                      //   toggle("add");
                    }}
                  >
                    <Icon name="plus"></Icon>
                    <span>Add Reminder & Notifications</span>
                  </Button>
                </div>
              </BlockHeadContent> */}
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <ReactDataTable
              data={tableData}
              columns={reminderAndNotificationsColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              pagination
              totalItems={totalItems}
              // progressPending={isFetching}
              className="nk-tb-list"
              selectableRows
              changePageState={changePageState}
            />
          </PreviewCard>
        </Block>
      </Content>
    </>
  );
};

export default ReminderAndNotifications;
