import moment from "moment";
import {
  BatchMenuComponent,
  Icon,
  TooltipComponent,
} from "../../components/Components";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { useSelector } from "react-redux";
import { toggleModal as attendanceToggleModal } from "../../redux/features/attendanceSlice";
import { toggleModal as spLogsToggleModal } from "../../redux/features/supportLogsSlice";
import { toast } from "react-toastify";

export const workTypeOptions = [
  {
    value: "internship",
    label: "Internship",
  },
  {
    value: "employment",
    label: "Employment",
  },
];

export const locationOptions = [
  {
    value: "online",
    label: "Online",
  },
  {
    value: "in_person",
    label: "In-person",
  },
];

export const observationGradingOptions = [
  {
    value: "Severe Lacking",
    label: "Severe Lacking",
  },
  {
    value: "Need improvement",
    label: "Need improvement",
  },
  {
    value: "Developing",
    label: "Developing",
  },
  {
    value: "Good development",
    label: "Good development",
  },
  {
    value: "Well developed",
    label: "Well developed",
  },
];

export const attendancesColumns = [
  {
    name: "Email",
    selector: (row) => row.email,
    compact: true,
    // grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.email}</span>,
    sortable: true,
  },
  {
    name: "Checked in",
    selector: (row) => row.is_checked_in,
    minWidth: "120px",
    cell: (row) => (
      <span>
        {row.checked_in_date_time &&
          moment(row.checked_in_date_time).format("DD-MM-YYYY, hh:mm:ss a")}
      </span>
    ),
    // sortable: true,
    hide: 480,
  },
  {
    name: "Checked out",
    selector: (row) => row.is_checked_out,
    minWidth: "120px",
    cell: (row) => (
      <span>
        {row.checked_out_date_time &&
          moment(row.checked_out_date_time).format("DD-MM-YYYY, hh:mm:ss a")}
      </span>
    ),
    // sortable: true,
    hide: "md",
  },
  {
    name: (
      <BatchMenuComponent
        getState={() => useSelector((state) => state.attendance)}
        onRemoveItemsClicked={(state, dispatch) => {
          if (state.selectedRows && state.selectedRows.length > 0) {
            dispatch(attendanceToggleModal({ type: "removeItems" }));
          } else {
            toast.error("There are no selected items to remove.");
          }
        }}
      />
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const appAttendancesColumns = [
  {
    name: "Name",
    compact: true,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{`${row.first_name} ${row.last_name}`}</span>,
  },
  {
    name: "Action",
    selector: (row) => row.type,
    minWidth: "120px",
    cell: (row) => <span>{row.type === "check_in" ? "Check in": "Check out"}</span>,
    hide: "md",
  },
  {
    name: "Location",
    selector: (row) => row.type,
    minWidth: "120px",
    cell: (row) => (
      <div>
        <div>
          <span>{`Latitude: ${row.latitude}`}</span>
        </div>
        <div>
          <span>{`Longitude: ${row.longitude}`}</span>
        </div>
      </div>
    ),
    hide: "md",
  },
  {
    name: "Date time",
    selector: (row) => row.created_time,
    minWidth: "120px",
    cell: (row) => <span>{moment(row.created_time).format("DD-MM-YYYY, hh:mm:ss a")}</span>,
    hide: "md",
  },
  {
    name: (
      <BatchMenuComponent
        getState={() => useSelector((state) => state.attendance)}
        onRemoveItemsClicked={(state, dispatch) => {
          if (state.selectedRows && state.selectedRows.length > 0) {
            dispatch(attendanceToggleModal({ type: "removeItems" }));
          } else {
            toast.error("There are no selected items to remove.");
          }
        }}
      />
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const supportMetricsColumns = [
  {
    name: "Name",
    selector: (row) => row.name,
    compact: true,
    // grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.name}</span>,
    sortable: true,
  },
  {
    name: "Description",
    selector: (row) => row.description,
    minWidth: "120px",
    cell: (row) => <span>{row.description}</span>,
    // sortable: true,
    hide: 480,
  },
  {
    name: "Target",
    selector: (row) => row.target,
    minWidth: "120px",
    cell: (row) => <span>{row.target}</span>,
    sortable: true,
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#markasdone"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorMarkAsDelivered();
                }}
              >
                <Icon name="truck"></Icon>
                <span>Mark As Delivered</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Orders</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Orders</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Orders</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Orders</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Orders</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const supportLogsColumns = [
  {
    name: "Email",
    selector: (row) => row.email,
    compact: true,
    // grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.email}</span>,
    sortable: true,
  },
  {
    name: "For Email",
    selector: (row) => row.for_email,
    minWidth: "120px",
    cell: (row) => <span>{row.for_email}</span>,
    sortable: true,
    hide: "md",
  },
  {
    name: "Work Type",
    selector: (row) => row.work_type,
    minWidth: "120px",
    cell: (row) => <span>{row.work_type}</span>,
    hide: 480,
  },
  {
    name: "Location",
    selector: (row) => row.location,
    minWidth: "120px",
    cell: (row) => <span>{row.location}</span>,
    hide: 480,
  },
  {
    name: "Observation Grading",
    minWidth: "120px",
    cell: (row) => <span>{row.observation_grading}</span>,
    hide: 480,
  },
  {
    name: "Created",
    selector: (row) => row?.created_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => (
      <span>{moment(row?.created_time).format("DD-MM-YYYY, hh:mm:ss a")}</span>
    ),
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row?.last_updated_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => (
      <span>
        {moment(row?.last_updated_time).format("DD-MM-YYYY, hh:mm:ss a")}
      </span>
    ),
    hide: "md",
  },
  {
    name: (
      <BatchMenuComponent
        getState={() => useSelector((state) => state.supportLogs)}
        onRemoveItemsClicked={(state, dispatch) => {
          if (state.selectedRows && state.selectedRows.length > 0) {
            dispatch(spLogsToggleModal({ type: "removeItems" }));
          } else {
            toast.error("There are no selected items to remove.");
          }
        }}
      />
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const locationTrackingColumns = [
  {
    name: "Email",
    selector: (row) => row.email,
    compact: true,
    // grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.email}</span>,
    sortable: true,
  },
  {
    name: "Time",
    selector: (row) => row.timestamp,
    minWidth: "120px",
    cell: (row) => <span>{moment(row.timestamp).format("DD-MM-YYYY hh:mm:ss a")}</span>,
    hide: 480,
  },
  {
    name: "Latitude",
    selector: (row) => row.coordinates?.latitude,
    minWidth: "120px",
    cell: (row) => <span>{row.coordinates?.latitude}</span>,
    hide: 480,
  },
  {
    name: "Longitude",
    selector: (row) => row.coordinates?.longitude,
    minWidth: "120px",
    cell: (row) => <span>{row.coordinates?.longitude}</span>,
    hide: "md",
  },
  {
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"open-map-" + row.id}
              icon="map"
              direction="top"
              text="Open map"
              data-tag="allowRowEvents"
              data-action="open-map"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#openmap"
                      data-tag="allowRowEvents"
                      data-action="open-map"
                    >
                      <Icon
                        name="map"
                        data-tag="allowRowEvents"
                        data-action="open-map"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="open-map">
                        Open Map
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];
