import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect } from "react";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  ReactDataTable,
} from "../../../components/Components";
import { locationTrackingColumns } from "./DataColumns";
import { onChangePageState } from "../../../redux/features/app-portal/config/locationTrackingSlice";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";

const LocationTracking = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.appConfigLocationTracking);
  // const { data, isFetching, isSuccess } = useGetGoalFrequenciesQuery({
  //   offset: state.offset,
  //   limit: state.itemsPerPage,
  //   total: true,
  // });
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  // useLayoutEffect(() => {
  //   if (data) {
  //     setTableData(data.goalFrequencies);
  //     setTotalItems(data.total);
  //   }
  // }, [data]);

  return (
    <>
      <Head title="Location Tracking"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Location Tracking</BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <ReactDataTable
              data={tableData}
              columns={locationTrackingColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              pagination
              totalItems={totalItems}
              // progressPending={isFetching}
              className="nk-tb-list"
              selectableRows
              changePageState={changePageState}
            />
          </PreviewCard>
        </Block>
      </Content>
    </>
  );
};

export default LocationTracking;
