import { useCallback, useState } from "react";
import { Handle, Position } from "reactflow";
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Col, Icon, Row } from "../../../components/Components";
import SimpleBar from "simplebar-react";

const handleStyle = { left: 10 };

export const TextUpdaterNode = ({ data, isConnectable }) => {
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  return (
    <div className="text-updater-node">
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <div>
        <label htmlFor="text">Text:</label>
        <input id="text" name="text" onChange={onChange} className="nodrag" />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={handleStyle}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
      />
    </div>
  );
};

export const TestNode = ({ data }) => {
  const [isDropdownMenuOpen, setDropdownMenuOpen] = useState(false);

  const toggleDropdownMenu = () =>
    setDropdownMenuOpen((prevState) => !prevState);

  return (
    <div
      className="nowheel"
      style={{
        padding: "5px 10px",
        width: 150,
        backgroundColor: "white",
        borderRadius: 5,
        boxShadow: "0 0 6px 4px #77777745",
      }}
    >
      <div className="mb-1">
        <span
          style={{
            fontSize: 11,
            marginRight: 5,
          }}
        >
          {data.componentsTab.toUpperCase()}
        </span>
        <Dropdown isOpen={isDropdownMenuOpen} toggle={toggleDropdownMenu}>
          <DropdownToggle
            tag="a"
            href="#dropdown"
            onClick={(ev) => ev.preventDefault()}
          >
            <div>
              <Icon name="arrow-down" style={{ fontSize: "9px" }}></Icon>
            </div>
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-xxs"
            onBlur={() => toggleDropdownMenu()}
          >
            <ul className="p-1">
              {menuName.map((item, index) => (
                <li
                  key={`flow-builder-option-node-${item}`}
                  className="text-center"
                  style={{ fontSize: "9px" }}
                >
                  <span
                    style={{ color: "#854fff", cursor: "pointer" }}
                    onClick={() => {
                      data.setComponentsTab(item);
                      toggleDropdownMenu();
                    }}
                  >
                    {item.toUpperCase()}
                  </span>
                </li>
              ))}
            </ul>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div>
        <input
          type="search"
          height="15px"
          className="form-control form-control-sm"
          placeholder={""}
          onChange={(e) => {}}
        />
      </div>
      <SimpleBar
        style={{
          overflowX: "hidden",
          height: 150,
        }}
      >
        {data.componentsTab === "intents" && (
          <ComponentTabNodeIntentList data={data} />
        )}
        {data.componentsTab === "utterances" && (
          <ComponentTabNodeUtteranceList data={data} />
        )}
      </SimpleBar>
    </div>
  );
};

const ComponentTabNodeIntentList = ({ data }) => {
  return (
    <div className="d-flex flex-column mt-1">
      {data?.intents.map((item, index) => (
        <Row key={`components-tab-node-${data.componentsTab}-${item.id}`}>
          <Col sm={8}>
            <span
              style={{
                fontSize: "8px",
                margin: "2px 0",
              }}
            >
              {item.name}
            </span>
          </Col>
          <Col
            sm={4}
            className="d-flex justify-content-between align-items-center"
          >
            <Icon
              name="plus"
              style={{ fontSize: "8px", cursor: "pointer" }}
              onClick={() => data.addToStory({ type: "intents", data: item })}
            />
          </Col>
        </Row>
      ))}
    </div>
  );
};

const ComponentTabNodeUtteranceList = ({ data }) => {
  return (
    <div className="d-flex flex-column mt-1">
      {data?.utterances.map((item, index) => (
        <Row key={`components-tab-node-${data.componentsTab}-${item.id}`}>
          <Col sm={8}>
            <span
              style={{
                fontSize: "8px",
                margin: "2px 0",
              }}
            >
              {item.name}
            </span>
          </Col>
          <Col
            sm={4}
            className="d-flex justify-content-between align-items-center"
          >
            <Icon
              name="plus"
              style={{ fontSize: "8px", cursor: "pointer" }}
              onClick={() =>
                data.addToStory({ type: "utterances", data: item })
              }
            />
          </Col>
        </Row>
      ))}
    </div>
  );
};

export const ComponentNode = ({ data }) => {
  const [showButton, setShowButton] = useState(false);

  return (
    <div
      className="nowheel"
      style={{ borderRadius: "50%", cursor: "pointer" }}
      onMouseEnter={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
    >
      <Icon
        style={{
          visibility: showButton ? "visible" : "hidden",
          padding: "5px",
          borderRadius: "50%",
          backgroundColor: "#e5e9f2",
          position: "absolute",
          top: "10px",
          right: "-10px",
          fontSize: "10px",
          cursor: "pointer",
        }}
        name="cross"
        onClick={(ev) => {
          ev.preventDefault();
          //TODO: Implement delete node
          // data.onNodeDelete(data.nodeId);
        }}
      />
      <Handle type="target" position={Position.Top} />
      <div
        className="d-flex flex-column"
        style={{
          padding: "5px 20px 5px 10px",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
        onClick={() => data.onNodeClicked({ type: data.type, item: data.item })}
      >
        <span style={{ fontSize: "10px" }}>{data.item.name}</span>
        <span style={{ fontSize: "8px", color: "#854fff" }}>
          {data.type.toUpperCase()}
        </span>
      </div>
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

var menuName = ["intents", "utterances", "actions", "forms"];
