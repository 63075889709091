import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Row,
  Col,
  ModalComponent,
  TextInput,
  ReactDataTable,
  OverlineTitle,
  TextareaInput,
} from "../../../components/Components";
import {
  Breadcrumb,
  BreadcrumbItem,
  ButtonGroup,
  Card,
  Progress,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import { useState } from "react";
import classnames from "classnames";
import { TabsGroup } from "./Tabs";
import { IntentsComponent } from "./IntentComponents";
import { UtterancesComponents } from "./UtterancesComponents";

const Components = ({}) => {
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <Head title="Components"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle page>Components</BlockTitle>
            </BlockHeadContent>
          </BlockHead>

          <Nav tabs className="mt-0 border-0">
            <TabsGroup
              className="mr-4"
              activeTab={activeTab}
              titleGroup="Story"
              tabs={[
                { title: "Intents", index: "1" },
                { title: "Utterances", index: "2" },
                { title: "Actions", index: "3" },
              ]}
              onTabClick={(index) => {
                toggle(index);
              }}
            />
            <TabsGroup
            className="mx-4"
              activeTab={activeTab}
              titleGroup="Form"
              tabs={[
                { title: "Slot Mappings", index: "4" },
              ]}
              onTabClick={(index) => {
                toggle(index);
              }}
            />
            <TabsGroup
            className="ml-4"
              activeTab={activeTab}
              titleGroup="Entity Extraction"
              tabs={[
                { title: "Lookup", index: "5" },
                { title: "Synonym", index: "6" },
                { title: "Regex", index: "7" },
              ]}
              onTabClick={(index) => {
                toggle(index);
              }}
            />

            
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <IntentsComponent />
            </TabPane>
            <TabPane tabId="2">
              <UtterancesComponents />
            </TabPane>
          </TabContent>

          {/* <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => {
              console.log(index);
              setTabIndex(index);
            }}
          >
            <TabList className="d-flex mb-3">
              <div>
                <OverlineTitle tag="span" className="preview-title">
                  Story
                </OverlineTitle>
                <Tab>Intents</Tab>
                <Tab>Utterances</Tab>
                <Tab>Actions</Tab>
              </div>
              <div>
                <OverlineTitle tag="span" className="preview-title">
                  Form
                </OverlineTitle>
                <Tab tabIndex="3">Slot Mappings</Tab>
              </div>
              <div>
                <OverlineTitle tag="span" className="preview-title">
                  Entity Extraction
                </OverlineTitle>
                <Tab tabIndex="4">Lookup</Tab>
                <Tab tabIndex="5">Synonym</Tab>
                <Tab tabIndex="6">Regex</Tab>
              </div>
            </TabList>

            <TabPanel forceRender>
              <Component />
            </TabPanel>
            <TabPanel forceRender>
              <Component />
            </TabPanel>
            <TabPanel forceRender>
              <Component />
            </TabPanel>
            <TabPanel><div>adasdasd</div></TabPanel>
            <TabPanel><div></div></TabPanel>
            <TabPanel><div></div></TabPanel>
            <TabPanel><div></div></TabPanel>
          </Tabs> */}
        </Block>
      </Content>
    </>
  );
};

// const CustomTabGroup = ({ children, ...otherProps }) => {
//   return (

//   );
// };

const Component = ({}) => {
  return (
    <Row className="g-5">
      <Col sm="3">
        <input
          type="search"
          className="form-control form-control-sm"
          placeholder={""}
          onChange={(e) => {}}
        />
        <ul>
          {testData.map((item, index) => (
            <li className="d-flex my-2">
              <div>{item.name}</div>
              <div style={{ marginLeft: "auto" }}>{item.number}</div>
            </li>
          ))}
        </ul>
      </Col>
      <Col sm="6">
        <Row className="g-gs">
          <Col sm="8">
            <h6>Test</h6>
          </Col>
          <Col sm="4">
            <input
              type="search"
              className="form-control form-control-sm"
              placeholder={""}
              onChange={(e) => {}}
            />
          </Col>
        </Row>
        <PreviewCard className="mt-2">
          <Row className="g-2">
            <Col sm="6">
              <span>Examples</span>
            </Col>
          </Row>
          <TextareaAutosize
            className="no-resize form-control mt-2"
            placeholder={"placeholder"}
            minRows={4}
            onChange={(e) => {}}
            onKeyDown={(e) => handleEnter(e)}
          />
          <Row className="g-2 align-items-center">
            <Col>
              <span>Press Shift + Enter to save</span>
            </Col>
            <Col>
              <Button>Add Entity For</Button>
            </Col>
          </Row>
          <ul className="g-3">
            {testData?.map((opt, idx) => {
              return (
                <li key={`example-option-${idx}`}>
                  <div className="custom-control custom-control-sm custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input form-control"
                      id={`example-${idx}`}
                      value={opt.number}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`example-${idx}`}
                    >
                      {opt.name}
                    </label>
                  </div>
                </li>
              );
            })}
          </ul>
        </PreviewCard>
      </Col>
      <Col sm="3">
        <h6>Corresponding Stories</h6>
      </Col>
    </Row>
  );
};

const handleEnter = (evt) => {
  if (evt.keyCode === 13 && evt.shiftKey) {
    evt.preventDefault();
    console.log("Enter");
  }
};

const testData = [
  {
    name: "Test",
    number: 123,
  },
  {
    name: "Test2",
    number: 123,
  },
  {
    name: "Test3",
    number: 123,
  },
  {
    name: "Test4",
    number: 123,
  },
  {
    name: "Test5",
    number: 123,
  },
  {
    name: "Test6",
    number: 123,
  },
  {
    name: "Test7",
    number: 123,
  },
  {
    name: "Test8",
    number: 123,
  },
  {
    name: "Test9",
    number: 123,
  },
  {
    name: "Test10",
    number: 123,
  },
  {
    name: "Test11",
    number: 123,
  },
  {
    name: "Test12",
    number: 123,
  },
  {
    name: "Test13",
    number: 123,
  },
  {
    name: "Test14",
    number: 123,
  },
  {
    name: "Test15",
    number: 123,
  },
  {
    name: "Test16",
    number: 123,
  },
  {
    name: "Test17",
    number: 123,
  },
  {
    name: "Test18",
    number: 123,
  },
  {
    name: "Test19",
    number: 123,
  },
  {
    name: "Test20",
    number: 123,
  },
  {
    name: "Test21",
    number: 123,
  },
  {
    name: "Test22",
    number: 123,
  },
  {
    name: "Test23",
    number: 123,
  },
  {
    name: "Test24",
    number: 123,
  },
  {
    name: "Test25",
    number: 123,
  },
  {
    name: "Test26",
    number: 123,
  },
  {
    name: "Test27",
    number: 123,
  },
  {
    name: "Test28",
    number: 123,
  },
];

export default Components;
