import { useDispatch, useSelector } from "react-redux";
import React, { useState, useLayoutEffect, useEffect } from "react";
import ReactDataTable from "../../../components/table/ReactDataTable";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  SelectField,
  Col,
  Row,
  ModalComponent,
  DatePickerInput,
  CheckboxInput,
  UserAvatar,
  TextareaInput,
} from "../../../components/Components";
import { communityPostColumns } from "./DataColumns";
import {
  onChangePageState,
  toggleModal,
  onFormCancel,
} from "../../../redux/features/app-portal/support/communitySlice";
import { Progress, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  useCreateCommunityPostMutation,
  useGetCommunityPostsQuery,
} from "../../../redux/api/community/communityApi";
import { findUpper } from "../../../utils/utils";
import { useDropzone } from "react-dropzone";

const Community = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.communityPost);
  const defaultValue = {};
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: defaultValue,
  });
  const { data, isFetching, isSuccess } = useGetCommunityPostsQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
  });
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [
    createPost,
    {
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreateCommunityPostMutation();
  const [files, setFiles] = useState([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          console.log(err);

          if (err.code === "file-too-large") {
            toast.error("File too large");
          }

          if (err.code === "file-invalid-type") {
          }
        });
      });
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.posts);
      setTotalItems(data.total);
    }
  }, [data]);

  const onFormSubmit = (data) => {
    var newData = processData(data);
    createPost(newData);
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(toggleModal({ type: "add" }));
      reset();
      setFiles([]);
    }
    if (isCreateError && createError) {
      toast.error(createError?.data?.message);
    }
  }, [isCreateSuccess, isCreateError]);

  const onAddBtnClicked = (e) => {
    dispatch(toggleModal({ type: "add" }));
  };

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "remove":
        //dispatch delete
        break;
      case "details":
        dispatch(toggleModal({ type: "details", item: row }));
        break;
    }
  };

  const processData = (rawData) => {
    var data = { ...rawData };
    return data;
  };

  return (
    <>
      <Head title="Community Post" />
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Community Post</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle btn-icon d-md-none"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                  </Button>
                  <Button
                    className="toggle d-none d-md-inline-flex"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                    <span>Add Post</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <div className="table-wrapper is-scrollable">
              <ReactDataTable
                data={tableData}
                columns={communityPostColumns}
                offset={state.offset}
                itemsPerPage={state.itemsPerPage}
                pagination
                totalItems={totalItems}
                progressPending={isFetching}
                className="nk-tb-list"
                selectableRows
                changePageState={changePageState}
                onRowClicked={onRowClicked}
              />
            </div>
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.details}
          toggle={() => dispatch(toggleModal({ type: "details" }))}
          title={"Community Post Details"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {state.modal.item && (
            <Col lg={12}>
              <Row className="gy-3 align-items-center">
                <UserAvatar
                  text={findUpper(state.modal.item.organisation.name)}
                  className="chat-media"
                />
                <Col lg={6}>
                  <div className="caption-text">
                    {state.modal.item.organisation.name}
                  </div>
                  <div className="sub-text">
                    {state.modal.item.created_time}
                  </div>
                </Col>
              </Row>
              <Row className="gy-3 align-items-center">
                <div className="caption-text">
                  {state.modal.item.content_text}
                </div>
              </Row>
              <Row className="gy-3 align-items-center">
                {state.modal.item?.media_urls?.map((url, index) => (
                  <img
                    key={`${state.modal.item.id}-media-${index}`}
                    src={url}
                    alt="post"
                    style={{ margin: "5px" }}
                  />
                ))}
              </Row>
            </Col>
          )}
        </ModalComponent>

        <ModalComponent
          isOpen={state.modal.add}
          toggle={() => dispatch(onFormCancel())}
          backdrop={"static"}
          title={"Add Community Post"}
          onModalCancel={() => {
            dispatch(onFormCancel());
            reset();
            setFiles([]);
          }}
        >
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Row className="g-3">
              <TextareaInput
                name={"content_text"}
                label={"Content Text"}
                register={{
                  ...register("content_text"),
                }}
                errors={errors}
                md="12"
              />

              <Col size="12">
                <section>
                  <div
                    {...getRootProps()}
                    className="dropzone upload-zone dz-clickable h-min-100px"
                  >
                    <input {...getInputProps()} />
                    {files.length === 0 && (
                      <div className="dz-message">
                        <span className="dz-message-text">
                          Drag and drop file
                        </span>
                        <span className="dz-message-or">or</span>
                        <Button type="button" color="primary">SELECT</Button>
                      </div>
                    )}
                    {files.map((file) => (
                      <React.Fragment key={`file-name-${file.name}`}>
                        <div>{file.name}</div>
                      </React.Fragment>
                    ))}
                  </div>
                </section>
              </Col>

              <Col size="12">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isCreateLoading}
                >
                  {isCreateLoading && <Spinner type="grow" size="sm" />}
                  <span>Add</span>
                </Button>
              </Col>
            </Row>
          </form>
        </ModalComponent>
      </Content>
    </>
  );
};

export default Community;
