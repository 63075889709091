import { Col } from "../Components";
import { Controller } from "react-hook-form";
import { RSelect } from "../Components";
import AsyncSelect from "react-select/async";
import { Button } from "reactstrap";

export const SelectField = ({
  name,
  label,
  caption,
  control,
  rules,
  errors,
  onChangeFunc,
  md,
  ...props
}) => {
  const splittedS = name.split(".");
  let errField = null;
  if (errors[splittedS[0]] && splittedS.length === 3) {
    const arrFieldName = splittedS[0];
    const index = parseInt(splittedS[1]);
    const fieldName = splittedS[2];

    errField = errors[arrFieldName][index]?.[fieldName];
  }

  return (
    <Col md={md ?? "6"} className={"form-group-wrapper"}>
      <div className="form-group">
        {label && (
          <label className="form-label">
            {label}{" "}
            {rules && rules.required && (
              <span className="required-asterisk">*</span>
            )}
          </label>
        )}
        {caption && <div className="form-caption">{caption}</div>}
        <div className="form-control-wrap">
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <RSelect onChangeFunc={onChangeFunc} field={field} {...props} />
            )}
          />
          {errors && errors[name] && (
            <span className="invalid">{errors[name].message}</span>
          )}
          {errors && errField && (
            <span className="invalid">{errField.message}</span>
          )}
        </div>
      </div>
    </Col>
  );
};

export const FlagsSelectField = ({
  name,
  label,
  caption,
  control,
  rules,
  errors,
  md,
  ...props
}) => {
  return (
    <Col md={md ?? "6"} className={"form-group-wrapper"}>
      <div className="form-group">
        <label className="form-label">
          {label}{" "}
          {rules && rules.required && (
            <span className="required-asterisk">*</span>
          )}
        </label>
        {caption && <div className="form-caption">{caption}</div>}
        <div className="form-control-wrap">
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => {
              return <RSelect field={field} isFlagsSelect {...props} />;
            }}
          />
        </div>
        {errors && errors[name] && (
          <span className="invalid">{errors[name].message}</span>
        )}
      </div>
    </Col>
  );
};

export const AsyncSelectField = ({
  isLoading,
  noOptionsMessage,
  onChangeFunc,
  loadOptions,
  ...props
}) => {
  return (
    <div className={`form-control-select ${isLoading ? "is-loading" : ""}`}>
      <AsyncSelect
        closeMenuOnSelect
        noOptionsMessage={
          noOptionsMessage ? noOptionsMessage : () => "No results found"
        }
        loadOptions={loadOptions}
        classNamePrefix={"react-select"}
        cacheOptions
        onChange={onChangeFunc}
        // isLoading={isLoading}
        // isDisabled={isLoading}
        {...props}
      />
    </div>
  );
};
