import { useContext, useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import UserAvatar from "../../../../components/user/UserAvatar";
import { LinkItem, LinkList } from "../../../../components/links/Links";
import { Icon } from "../../../../components/Components";
import { useLogoutMutation } from "../../../../redux/api/authApi";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../../redux/features/authSlice";
import { ChatContext } from "../../../../pages/chat/ChatContext";

const User = () => {
  const { user } = useSelector((state) => state.auth);
  const { disconnect } = useContext(ChatContext);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prevState) => !prevState);
  const [logout, { isSuccess }] = useLogoutMutation();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      const response = await logout().unwrap();
      dispatch(logoutUser());
      disconnect();
    } catch (err) {
      //show toast
    }
  };

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <UserAvatar icon="user-alt" className="sm" />
          <div className="user-info d-none d-md-block">
            <div className="user-status">{user?.role_name}</div>
            <div className="user-name dropdown-indicator">{user?.name}</div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <div className="user-avatar">
              <span>AB</span>
            </div>
            <div className="user-info">
              <span className="lead-text">{`${user?.first_name ?? ""} ${
                user?.last_name ?? ""
              }`}</span>
              <span className="sub-text">{user?.role_name}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem
              link="/user-profile-regular"
              icon="user-alt"
              onClick={toggle}
            >
              View Profile
            </LinkItem>
            <LinkItem
              link="/user-profile-setting"
              icon="setting-alt"
              onClick={toggle}
            >
              Account Setting
            </LinkItem>
            <LinkItem
              link="/user-profile-activity"
              icon="activity-alt"
              onClick={toggle}
            >
              Login Activity
            </LinkItem>
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <a href="#links" onClick={handleLogout}>
              <Icon name="signout"></Icon>
              <span>Log Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
