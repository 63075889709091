import classnames from "classnames";
import { OverlineTitle } from "../../../components/Components";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

export const TabsGroup = ({
  activeTab,
  titleGroup,
  tabs,
  onTabClick,
  ...props
}) => {
  return (
    <div {...props}>
      {titleGroup && (
        <OverlineTitle tag="span" className="mb-3">
          {titleGroup}
        </OverlineTitle>
      )}
      <div
        className="d-flex"
        style={{ backgroundColor: "#f0eaff", borderRadius: "5px" }}
      >
        {tabs.map((tab, index) => (
          <div key={`tabsgroup-tabs-${tab.title}`} style={{ padding: "5px" }}>
            <div
              className={classnames({ active: activeTab === tab.index })}
              onClick={(ev) => {
                ev.preventDefault();
                onTabClick(tab.index);
              }}
              style={{
                cursor: "pointer",
                padding: "0.6rem 1.8rem",
                borderRadius: activeTab === tab.index ? "5px" : "0",
                backgroundColor:
                  activeTab === tab.index ? "#fff" : "transparent",
              }}
            >
              <span
                style={{
                  color: "#9d72ff",
                  fontWeight: 700,
                  fontSize: ".875rem",
                }}
              >
                {tab.title}
              </span>
            </div>
          </div>

          //   <NavItem
          //     key={`tabgroup-${titleGroup}-${tab.title}`}
          //     style={{
          //     //   padding: "0 2rem",
          //     //   backgroundColor: "#f0eaff",
          //     }}
          //   >
          //     <NavLink
          //       tag="a"
          //       href="#tab"
          //       className={classnames({ active: activeTab === tab.index })}
          //       onClick={(ev) => {
          //         ev.preventDefault();
          //         onTabClick(tab.index);
          //       }}
          //     >
          //       {tab.title}
          //     </NavLink>
          //   </NavItem>
        ))}
      </div>
    </div>
  );
};
